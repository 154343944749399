'use client'
import * as React from 'react'

export const ScrollResetOnReload = () => {
  React.useEffect(() => {
    const scrollTop = () => {
      window.scrollTo(0, 0)
    }
    window.addEventListener('beforeunload', scrollTop)
    return () => {
      window.removeEventListener('beforeunload', scrollTop)
    }
  })
  return null
}
