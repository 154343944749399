'use client'
import * as React from 'react'
import * as Dialog from 'primitives/dialog'

import { NotEnoughCoinsDialogBody } from './components/not-enough-coins-dialog-body'
import { useNotEnoughCoinsDialog } from '@ask-cf/contexts/not-enough-coins-dialog-context'

export function NotEnoughCoinsDialog() {
  const { isOpen, closeDialog } = useNotEnoughCoinsDialog()

  return (
    <Dialog.Root open={isOpen} onOpenChange={open => !open && closeDialog()} defaultOpen={false}>
      <React.Suspense fallback={null}>
        <Dialog.Portal>
          <Dialog.Body
            className="font-poppins z-50 xl:overflow-y-auto xl:py-10"
            overlayZIndex={49}
            zIndex={49}
          >
            <NotEnoughCoinsDialogBody />
          </Dialog.Body>
        </Dialog.Portal>
      </React.Suspense>
    </Dialog.Root>
  )
}
