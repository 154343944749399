import { useLogInMutation } from 'codegen/generated/user'
import { ChallengeType } from 'codegen/types'
import { saveToken, UserAuthResponse } from 'utils/auth'

type UseLoginProps = {
  onSuccess?: (user: UserAuthResponse) => void
}

export function useLogin({ onSuccess }: UseLoginProps) {
  const loginMutation = useLogInMutation({
    onSuccess: async function onSuccessCallback(data) {
      if (data?.login?.token) {
        await saveToken(data.login.token, 'login').catch(() => {
          throw new Error('CF_INVALID_TOKEN')
        })
        if (onSuccess) {
          onSuccess(data.login)
        }
        return
      }

      // otp is handled in the onSuccess of the mutation in the form callback
      // as it needs access to the form data
      if (data.login?.challenge?.type === ChallengeType.ChallengeOtp) {
        return
      }

      if (!data.login?.challenge) {
        throw new Error('CF_INVALID_USER')
      }

      throw new Error('UNKNOWN_LOGIN_ERROR')
    },
  })

  return loginMutation
}
