'use client'
import * as React from 'react'
import Script from 'next/script'
import { useIsLoggedInQuery } from 'auth/hooks'
import {
  GoogleOneTapLogin as GoogleOneTapLoginComponent,
  scriptUrl as googleOneTapLoginScriptUrl,
} from 'analytics/google-one-tap-login'

export const GoogleOneTapLogin = () => {
  const isLoggedInQuery = useIsLoggedInQuery()
  const [scriptLoaded, setScriptLoaded] = React.useState(false)

  React.useEffect(() => {
    const scriptTag = document.getElementById('global-one-tap-login-script')
    if (scriptTag) {
      scriptTag.onload = () => {
        setScriptLoaded(true)
      }
    }
  }, [])

  React.useEffect(() => {
    // If user is already logged in and the script has finished loading, cancel the one-tap login
    if (isLoggedInQuery.data && scriptLoaded) {
      window.google?.accounts.id.cancel()
    }
  }, [isLoggedInQuery.data, scriptLoaded])

  if (isLoggedInQuery.isFetching || isLoggedInQuery.data) {
    return null
  }

  return (
    <>
      <GoogleOneTapLoginComponent shouldRedirect={false} />
      <Script
        async
        id="global-one-tap-login-script"
        type="text/javascript"
        src={googleOneTapLoginScriptUrl}
      />
    </>
  )
}
