import invariant from 'tiny-invariant'

const googleOneTapLoginClientId =
  process.env.NEXT_PUBLIC_GOOGLE_ONE_TAP_LOGIN_CLIENT_ID ||
  process.env.VITE_GOOGLE_ONE_TAP_LOGIN_CLIENT_ID
const googleOneTapLoginRedirectUrl =
  process.env.NEXT_PUBLIC_GOOGLE_ONE_TAP_LOGIN_REDIRECT_URL ||
  process.env.VITE_GOOGLE_ONE_TAP_LOGIN_REDIRECT_URL

invariant(
  googleOneTapLoginClientId,
  'GOOGLE_ONE_TAP_LOGIN_CLIENT_ID must be set inside google-one-tap-login',
)
invariant(
  googleOneTapLoginRedirectUrl,
  'GOOGLE_ONE_TAP_LOGIN_REDIRECT_URL must be set inside google-one-tap-login',
)

declare global {
  interface Window {
    google: {
      accounts: {
        id: {
          cancel: () => void
        }
      }
    }
  }
}

interface GoogleOneTapLoginProps {
  shouldRedirect?: boolean
}

export const GoogleOneTapLogin: React.FC<GoogleOneTapLoginProps> = ({ shouldRedirect = true }) => {
  return (
    <div
      id="g_id_onload"
      data-use_fedcm_for_prompt="true"
      data-client_id={googleOneTapLoginClientId}
      data-login_uri={shouldRedirect ? googleOneTapLoginRedirectUrl : ''}
      data-cancel_on_tap_outside="false"
      data-itp_support="true"
    ></div>
  )
}

export const scriptUrl = `https://accounts.google.com/gsi/client`
