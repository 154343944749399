'use client'

import * as React from 'react'
import { NextIntlClientProvider, AbstractIntlMessages } from 'next-intl'
import { DEFAULT_LOCALE, LOCALES } from 'utils/locales'

import { UserAuthDialog as UserAuthDialogComponent, UserAuthDialogProps } from './user-auth-dialog'

export function UserAuthDialog({
  locale = DEFAULT_LOCALE,
  ...userAuthDialogProps
}: {
  locale?: LOCALES
} & UserAuthDialogProps) {
  const [i18n, setI18n] = React.useState<AbstractIntlMessages | null>(null)

  React.useEffect(() => {
    async function fetchMessages() {
      const messages = await import(`./i18n/${locale}.json`)
      setI18n(messages.default)
    }

    fetchMessages()
  })

  if (i18n === null) {
    return null
  }

  return (
    <NextIntlClientProvider locale={locale} messages={i18n}>
      <UserAuthDialogComponent {...userAuthDialogProps} />
    </NextIntlClientProvider>
  )
}
