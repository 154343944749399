import invariant from 'tiny-invariant'

const bingTagId = process.env.NEXT_PUBLIC_BING_TAG_ID || process.env.VITE_BING_TAG

invariant(bingTagId, 'NEXT_PUBLIC_BING_TAG_ID must be set inside Bing')

export enum BingEventName {
  COMPLETE_REGISTRATION = 'signup',
}

export type BingEventOptions = { [key: string]: string | number } | void

declare global {
  interface Window {
    uetq?:
      | {
          push: (type: 'event', name: BingEventName, options: BingEventOptions) => void
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | Array<any>
  }
}

export const bingEvent = (name: BingEventName, options: BingEventOptions) => {
  window.uetq = window.uetq || []

  window.uetq?.push('event', name, options)
}

export const initScript = `(function(w,d,t,r,u)
{
  var f,n,i;
  w[u]=w[u]||[],f=function()
{
  var o={ti:"${bingTagId}"};
  o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
},
  n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
{
  var s=this.readyState;
  s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
},
  i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
}
)(window,document,"script","//bat.bing.com/bat.js","uetq");
`
