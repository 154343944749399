import { trackRegistrationConversion } from 'analytics/google-site-tag'
import { facebookEvent, FacebookEventName } from 'analytics/facebook'
import { bingEvent, BingEventName } from 'analytics/bing'

export async function trackRegistration({
  isGAdsSignup,
  isNew,
  userId,
}: {
  isGAdsSignup: boolean
  isNew: boolean
  userId: string
}) {
  if (isGAdsSignup) {
    trackRegistrationConversion()
  }

  if (isNew) {
    facebookEvent(FacebookEventName.COMPLETE_REGISTRATION)
    bingEvent(BingEventName.COMPLETE_REGISTRATION, {
      event_category: 'user',
      event_label: userId,
    })
  }
}
