import * as React from 'react'
import * as Dialog from 'primitives/dialog'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/navigation'

import { StyledButton } from '@ask-cf/components/styled-button'
import { useNotEnoughCoinsDialog } from '@ask-cf/contexts/not-enough-coins-dialog-context'
import { CF_URL } from '@ask-cf/config'

export function NotEnoughCoinsDialogBody() {
  const t = useTranslations('ask_cf.components.not_enough_coins_dialog')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { closeDialog } = useNotEnoughCoinsDialog()
  const router = useRouter()
  const redirect = () => {
    router.push(`${CF_URL}/coins`)
  }
  return (
    <section className="rounded-2 relative mx-4 flex flex-col items-center bg-white px-2 pb-6 pt-12 xl:w-[502px] xl:px-8">
      <h2 className="text-blue-1000 mb-[43px] max-w-[407px] text-center text-[20px] font-medium leading-[28px] xl:text-[28px] xl:leading-[39.2px]">
        {t('title')}
      </h2>
      <StyledButton
        variant="blue"
        size="large"
        className="w-full font-semibold leading-[24px]"
        onClick={redirect}
      >
        {t('button_label')}
      </StyledButton>
      <Dialog.CloseX
        className="hover:bg-text-hover right-0 top-0 z-50 h-7 w-7 -translate-y-1/2 translate-x-1/2 items-center justify-center bg-blue-700 text-white"
        onClick={closeDialog}
      />
    </section>
  )
}
