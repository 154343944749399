'use client'
import * as React from 'react'
import { useRegisterMutation } from 'codegen/generated/user'
import { ChallengeType } from 'codegen/types'
import { UserAuthResponse, getDeviceId, saveToken } from 'utils/auth'
import { LockFasIcon } from 'primitives/icons/security'
import { MailFasIcon } from 'primitives/icons/communication'
import { EyeFasIcon, EyeSlashFasIcon } from 'primitives/icons/general'
import { CheckIcon, SpinnerIcon } from 'primitives/icons'
import * as Form from 'primitives/form'
import { tw } from 'utils/classnames'
import { Divider } from 'components/divider'
import { isError } from 'utils/js'
import { useTranslations } from 'next-intl'
import { isLoggedInSelector } from 'auth/selectors'

import { useUserAuthModal } from './user-auth-dialog-context'
import { SocialButtons } from './social-buttons'
import { useLogin } from './hooks/use-login'

export function RegisterForm({
  onLogin,
  onLoginOtp,
  onRegisterOtp,
  onRegisterSuccess,
  onLoginSuccess,
  onLostPassword,
}: {
  onLogin(formState: FormData): void
  onLoginOtp(formState: FormData): void
  onRegisterOtp(formState: FormData): void
  onRegisterSuccess(user: UserAuthResponse): void
  onLoginSuccess(user: UserAuthResponse): void
  onLostPassword(formState: FormData): void
}) {
  const t = useTranslations('register')
  const { formState, setErrorMessage } = useUserAuthModal()

  const [showPassword, setShowPassword] = React.useState(false)
  const termsForm = formState?.get('terms')?.toString() === 'on'
  const [terms, setTerms] = React.useState(termsForm)

  const loginMutation = useLogin({ onSuccess: onLoginSuccess })

  const ref = React.useRef<HTMLFormElement>(null)

  const registerMutation = useRegisterMutation({
    onMutate(variables) {
      if (variables.input?.password.length < 8) {
        throw new Error('CF_INVALID_PASSWORD')
      }
    },
    onSuccess: async function onSuccessCallback(data, variables) {
      if (data?.register?.token) {
        await saveToken(data.register.token, 'registration').catch(() => {
          throw new Error('CF_INVALID_TOKEN')
        })
        onRegisterSuccess(data.register)
        return
      }

      // otp is handled in the onSuccess of the mutation in the form callback
      // as it needs access to the form data
      if (data.register?.challenge?.type === ChallengeType.ChallengeOtp) {
        return
      }

      if (data.register?.errors?.find(err => err.message === 'terms')) {
        throw new Error('CF_INVALID_TERMS')
      }

      //if email already exists then try logging in
      if (data.register?.errors?.find(err => err.message === 'email')) {
        loginMutation.mutate(
          {
            input: {
              email: variables.input.email,
              password: variables.input.password,
              deviceId: variables.input.deviceId,
            },
          },
          {
            onSuccess: async function onSuccessCallback(data) {
              const formData = new FormData()
              formData.set('email', variables.input.email)
              formData.set('password', variables.input.password)

              if (data.login?.challenge?.type === ChallengeType.ChallengeOtp) {
                if (data.login.challenge.otp) {
                  formData.set('otp', data.login.challenge.otp?.toString() ?? '')
                }

                onLoginOtp(formData)
              }
            },
          },
        )

        return
      }

      if (!data.register?.challenge) {
        throw new Error('CF_INVALID_USER')
      }

      throw new Error('UNKNOWN_REGISTER_ERROR')
    },
  })

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const formData = new FormData(ref.current!)

    const email = formData.get('email')?.toString() ?? ''
    const password = formData.get('password')?.toString() ?? ''
    const terms = formData.get('terms')?.toString() === 'on'
    const deviceId = getDeviceId()

    registerMutation.mutate(
      {
        input: {
          email,
          password,
          terms,
          deviceId,
        },
      },
      {
        onSuccess: async function onSuccessCallback(data) {
          if (data.register?.challenge?.type === ChallengeType.ChallengeOtp) {
            if (data.register.challenge.otp) {
              formData.set('otp', data.register.challenge.otp?.toString() ?? '')
            }
            onRegisterOtp(formData)
          }
        },
      },
    )
  }

  function onLoginClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    const form = new FormData(ref.current!)
    onLogin(form)
  }

  function onLostPasswordClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault()
    const form = new FormData(ref.current!)
    onLostPassword(form)
  }

  React.useEffect(() => {
    function resolveErrorMessage() {
      if (
        registerMutation.isLoading ||
        loginMutation.isLoading ||
        (!registerMutation.isError && !loginMutation.isError)
      ) {
        return null
      }

      if (registerMutation.isPaused) {
        setErrorMessage(t('errors.offline'))
        return
      }

      if (
        isError(registerMutation.error) &&
        registerMutation.error.message === 'CF_INVALID_PASSWORD'
      ) {
        setErrorMessage(t('errors.short_password'))
        return
      }

      if (
        isError(registerMutation.error) &&
        registerMutation.error.message === 'CF_INVALID_TERMS'
      ) {
        setErrorMessage(t('errors.terms'))
        return
      }

      if (isError(registerMutation.error) && registerMutation.error.message === 'CF_INVALID_USER') {
        setErrorMessage(
          t.rich('errors.existing_email', {
            reset: str => (
              <span
                onClick={onLostPasswordClick}
                className="text-14 hover:text-service-white text-service-white m-auto mt-4 cursor-pointer font-bold underline"
              >
                {str}
              </span>
            ),
          }),
        )
        return
      }

      setErrorMessage(t.rich('errors.general', { br: () => <br /> }))
    }

    resolveErrorMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    registerMutation.isError,
    registerMutation.isLoading,
    registerMutation.isPaused,
    registerMutation.error,
    loginMutation.isLoading,
    loginMutation.isError,
  ])

  const disabled =
    registerMutation.isLoading ||
    loginMutation.isLoading ||
    isLoggedInSelector(registerMutation.data?.register) ||
    Boolean(registerMutation.data?.register?.token) ||
    Boolean(registerMutation.data?.register?.challenge?.type === ChallengeType.ChallengeOtp)

  return (
    <div className="flex w-full flex-col">
      <h2 className="text-20 mb-4 text-center font-bold">{t('title')}</h2>
      <SocialButtons />
      <div className="mt-4 flex w-full items-center gap-1">
        <div className="h-[1px] w-full bg-[#e3e1e9]"></div>
        <div className="text-12 text-[#a399b8]">{t('or')}</div>
        <div className="h-[1px] w-full bg-[#e3e1e9]"></div>
      </div>
      <Form.Root ref={ref} onSubmit={onSubmit} className="mt-4 flex w-full flex-col gap-4">
        <Form.Field name="email">
          <Form.Label className="text-12 align-text-top data-[invalid='true']:text-red-100">
            {t('email')} *
          </Form.Label>
          <Form.Message match="valueMissing">{''}</Form.Message>
          <Form.FormMessage match="typeMismatch">{''}</Form.FormMessage>
          <div className="relative flex w-full rounded border border-[#e0e2ea] pl-[10px]">
            <div className="pointer-events-none absolute inset-y-0 flex items-center">
              <MailFasIcon className="h-[14px] w-[14px] text-[#e3e1e9]" />
            </div>
            <Form.Control asChild>
              <input
                required
                defaultValue={formState?.get('email')?.toString()}
                autoComplete="email"
                type="email"
                className="text-14 h-[38px] w-full p-3 pl-7 outline-none"
                autoFocus
                disabled={disabled}
              />
            </Form.Control>
          </div>
        </Form.Field>
        <Form.Field name="password">
          <Form.Label className="text-12 align-text-top data-[invalid='true']:text-red-100">
            {t('password')} *
          </Form.Label>
          <Form.Message match="valueMissing">{''}</Form.Message>
          <div className="relative flex w-full rounded border border-[#e0e2ea] pl-[10px]">
            <div className="pointer-events-none absolute inset-y-0 flex items-center">
              <LockFasIcon className="h-[14px] w-[14px] text-[#e3e1e9]" />
            </div>
            <Form.Control asChild>
              <input
                required
                autoComplete="current-password"
                defaultValue={formState?.get('password')?.toString()}
                type={showPassword ? 'text' : 'password'}
                className="text-14 h-[38px] w-full py-3 pl-7 pr-[60px] outline-none"
                disabled={registerMutation.isLoading}
              />
            </Form.Control>
            <div
              onClick={() => {
                setShowPassword(prevValue => !prevValue)
              }}
              className="pointer-events absolute inset-y-0 right-3 flex items-center"
            >
              {showPassword ? (
                <EyeSlashFasIcon className="h-[16px] w-[16px] text-[#a399b8]" />
              ) : (
                <EyeFasIcon className="h-[14px] w-[14px] text-[#a399b8]" />
              )}
            </div>
          </div>
        </Form.Field>
        <Form.Field
          name="terms"
          className="outline-none focus-visible:outline"
          onChange={() => setTerms(prevValue => !prevValue)}
        >
          <Form.Control asChild>
            <input checked={terms} type="checkbox" className="sr-only" readOnly />
          </Form.Control>
          <Form.Label className="text-14 flex cursor-pointer items-center">
            <span
              className={tw(
                terms ? 'bg-[#0a5ea8]' : '',
                'text-service-white mr-2 flex h-[16px] w-[16px] items-center justify-center rounded border-2 border-[#0a5ea8]',
              )}
            >
              {terms ? <CheckIcon /> : null}
            </span>
            <span>
              {t.rich('terms', {
                link: str => (
                  <a
                    target="_blank"
                    className="text-[#3ab561] hover:text-[#248142]"
                    href="https://www.iubenda.com/privacy-policy/24976076/cookie-policy"
                    rel="noreferrer"
                    onClick={() => setTerms(true)}
                  >
                    {str}
                  </a>
                ),
              })}
            </span>
          </Form.Label>
        </Form.Field>
        <Form.Submit asChild>
          <button
            disabled={disabled}
            className="text-service-white text-14 mt-2 flex h-[45px] w-full items-center justify-center rounded bg-[#3ab561] font-bold uppercase transition-[background-color] hover:bg-[#248142]"
          >
            {disabled ? <SpinnerIcon className="h-[24px] w-[24px] animate-spin" /> : t('cta')}
          </button>
        </Form.Submit>
      </Form.Root>
      <Divider className="mt-2 bg-[#DFDDE5]" />
      <div className="text-14 m-auto mt-4">
        {t.rich('login', {
          login: str => (
            <span
              onClick={onLoginClick}
              className="text-14 cursor-pointer font-bold text-[#0a5ea8] underline hover:text-[#3ab561]"
            >
              {str}
            </span>
          ),
        })}
      </div>
    </div>
  )
}
