import * as React from 'react'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { ListItemNode, ListNode } from '@lexical/list'
import { LinkNode } from '@lexical/link'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { HeadingNode } from '@lexical/rich-text'
import { CodeNode } from '@lexical/code'
import { tw } from 'utils/classnames'
import { $convertFromMarkdownString } from '@lexical/markdown'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { COMMAND_PRIORITY_HIGH, PASTE_COMMAND, $insertNodes, $createTextNode } from 'lexical'

import { ToolbarPlugin } from './plugins/toolbar-plugin'
import { FloatingLinkEditorPlugin } from './plugins/floating-link-editor-plugin'
import {
  ALL_TRANSFORMERS,
  OnChangeHtmlPlugin,
} from './plugins/on-change-markdown-plugin/on-change-markdown-plugin'
import { EmojiNode } from './plugins/emoji-plugin/emoji-node'
import { NoFollowLinkNode } from './nodes/no-follow-link-node'

function PlainTextPastePlugin() {
  const [editor] = useLexicalComposerContext()

  React.useEffect(() => {
    const removePasteListener = editor.registerCommand(
      PASTE_COMMAND,
      (event: ClipboardEvent) => {
        event.preventDefault()
        const plainText = event.clipboardData?.getData('text/plain')
        if (plainText) {
          editor.update(() => {
            const textNode = $createTextNode(plainText)
            $insertNodes([textNode])
          })
        }
        return true
      },
      COMMAND_PRIORITY_HIGH,
    )
    return () => {
      removePasteListener()
    }
  }, [editor])

  return null
}

const theme = {
  link: 'text-cerulean-500 underline',
  list: {
    olDepth: ['list-inside list-decimal'],
    ul: 'list-inside list-disc',
  },
  text: {
    bold: 'font-bold',
    italic: 'italic',
    underline: 'underline',
  },
}

function RichTextEditor({
  className,
  placeholder = 'Enter some text...',
  onChange,
  initialValue,
  container,
  readonly = false,
}: {
  className?: string
  placeholder?: string
  onChange?: (value: string) => void
  initialValue?: string
  container?: HTMLElement | null
  readonly?: boolean
}) {
  const [isLinkEditMode, setIsLinkEditMode] = React.useState<boolean>(false)
  const initialConfig = {
    namespace: 'MyEditor',
    theme,
    onError: (e: Error) => {
      throw e
    },
    nodes: [
      ListNode,
      ListItemNode,
      NoFollowLinkNode,
      {
        replace: LinkNode,
        with: (node: LinkNode) => {
          return new NoFollowLinkNode(node.getURL())
        },
      },
      EmojiNode,
      HeadingNode,
      CodeNode,
    ],
    editable: !readonly,
    editorState: () =>
      $convertFromMarkdownString(initialValue || '', ALL_TRANSFORMERS, undefined, true),
  }
  const [floatingAnchorElem, setFloatingAnchorElem] = React.useState<HTMLDivElement | undefined>(
    undefined,
  )
  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem) {
      setFloatingAnchorElem(_floatingAnchorElem)
    }
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <PlainTextPastePlugin />
      <div
        className={tw(
          !readonly && 'bg-cf-white rounded-2 relative w-full border border-[#e7eaee]',
          className,
        )}
      >
        <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} container={container} />
        {!readonly ? (
          <FloatingLinkEditorPlugin
            anchorElem={floatingAnchorElem}
            isLinkEditMode={isLinkEditMode}
            setIsLinkEditMode={setIsLinkEditMode}
          />
        ) : null}
        <RichTextPlugin
          contentEditable={
            <div ref={onRef} className="h-[calc(100%-56px)]">
              <ContentEditable
                className={`h-full overflow-y-auto ${
                  !readonly ? 'px-6 pb-1.5' : ''
                } focus:border-0 focus:outline-none`}
              />
            </div>
          }
          placeholder={
            <div className="absolute left-6 top-[56px] text-[#02091D] opacity-50">
              {placeholder}
            </div>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <AutoFocusPlugin />
        <ListPlugin />
        <LinkPlugin />
        <OnChangeHtmlPlugin onChange={onChange} initialValue={initialValue} />
      </div>
    </LexicalComposer>
  )
}

export { RichTextEditor }
