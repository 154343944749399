import * as Dialog from '@radix-ui/react-dialog'
import { AnimatePresence, LazyMotion, m, domAnimation } from 'framer-motion'
import { CloseButton } from 'primitives/buttons/close-button'
import * as React from 'react'
import { tw } from 'utils/classnames'

import { useScrollbarWidth } from './hooks/use-scrollbar-width'

// eslint-disable-next-line no-barrel-files/no-barrel-files
export * from '@radix-ui/react-dialog'

const DialogContext = React.createContext<{
  open: boolean
  onOpenChange: (open: boolean) => void
  handleClose: () => void
}>(null!)

export function useDialog() {
  const context = React.useContext(DialogContext)

  if (!context) {
    throw new Error('useDialog must be used within a Dialog')
  }

  return context
}

export function Root({
  open: openControlled,
  onOpenChange: onOpenChangeControlled,
  defaultOpen,
  children,
}: {
  id?: string
  open?: boolean
  onOpenChange?: (open: boolean) => void
  defaultOpen?: boolean
  children: React.ReactNode
}) {
  const [open, onOpenChange] = React.useState(defaultOpen ?? false)

  const isControlled = openControlled !== undefined && onOpenChangeControlled !== undefined

  if (
    isControlled &&
    defaultOpen === undefined &&
    (process.env.DEV || process.env.NODE_ENV !== 'production')
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      'You are controlling Dialog.Root and not defining `defaultOpen`. This means the dialog will not be open by default. Please set `defaultOpen`',
    )
  }

  const value = React.useMemo(
    () => ({
      open: isControlled ? openControlled : open,
      onOpenChange: isControlled ? onOpenChangeControlled : onOpenChange,
      handleClose: () => (isControlled ? onOpenChangeControlled(false) : onOpenChange(false)),
    }),
    [isControlled, onOpenChangeControlled, open, openControlled],
  )

  return (
    <DialogContext.Provider value={value}>
      <Dialog.Root open={value.open} onOpenChange={value.onOpenChange}>
        {children}
      </Dialog.Root>
    </DialogContext.Provider>
  )
}

export function CloseX(props: React.ComponentProps<typeof CloseButton>) {
  return (
    <Dialog.Close asChild>
      <CloseButton
        {...props}
        className={tw('absolute right-2 top-2 text-gray-400', props.className)}
      />
    </Dialog.Close>
  )
}

function BodyRoot(
  {
    zIndex,
    overlayZIndex,
    dismissable = true,
    initial = true,
    children,
    className,
    placement = 'middle',
    overlayClassnames,
    ...props
  }: React.ComponentPropsWithoutRef<typeof Dialog.Content> & {
    dismissable?: boolean
    zIndex?: number
    overlayZIndex?: number
    initial?: boolean
    placement?: 'middle' | 'bottom' | 'top'
    overlayClassnames?: string
  },
  ref: React.Ref<HTMLDivElement>,
) {
  const { open, onOpenChange } = useDialog()

  const scrollbarWidth = useScrollbarWidth()

  function onCloseFromContentOverlay(event: React.MouseEvent<HTMLDivElement>) {
    if (dismissable && event.target === event.currentTarget) {
      onOpenChange(false)
    }
  }

  function onEscapeKeyDown(event: KeyboardEvent) {
    if (!dismissable) {
      event.preventDefault()
    }
  }

  React.useEffect(() => {
    if (open) {
      document.body.style.setProperty('--removed-body-scroll-bar-size', `${scrollbarWidth}px`)
    } else {
      document.body.style.overflow = ''
      document.body.style.removeProperty('--removed-body-scroll-bar-size')
    }
  }, [open, scrollbarWidth])

  return (
    <LazyMotion features={domAnimation}>
      <AnimatePresence initial={initial}>
        {open ? (
          <m.div
            initial={{
              opacity: 0,
            }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { ease: 'easeOut', duration: 0.15 } }}
            style={{ zIndex }} // I have added this line because in cf-main nav is 9999 =(
            key="overlay"
            className="relative"
          >
            {/* bg colour is hard coded so it's the same value on dark and light mode */}
            <Dialog.Overlay
              className={tw('fixed inset-0 bg-[#272B2F] bg-opacity-80', overlayClassnames)}
              style={{ zIndex: overlayZIndex }}
            />
          </m.div>
        ) : null}
        {open ? (
          <Dialog.Content
            key="content"
            asChild
            forceMount
            onEscapeKeyDown={onEscapeKeyDown}
            onClick={onCloseFromContentOverlay}
            ref={ref}
            {...props}
          >
            <m.div
              initial={{ opacity: 0, scale: 0.975 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9875, transition: { ease: 'easeOut', duration: 0.2 } }}
              className={tw(
                'fixed inset-0 flex h-[100dvh] w-screen justify-center outline-none',
                className,
                (!placement || placement === 'middle') && 'items-center',
                placement === 'top' && 'items-start',
                placement === 'bottom' && 'items-end',
              )}
              style={{ zIndex: zIndex ? zIndex + 1 : undefined }} // I have added this line because in cf-main nav is 9999 =(
            >
              {children}
            </m.div>
          </Dialog.Content>
        ) : null}
      </AnimatePresence>
    </LazyMotion>
  )
}

export const Body = React.forwardRef(BodyRoot)
