'use client'
import { CFAskLogo } from 'primitives/icons'
import { usePathname } from 'next/navigation'
import { tw } from 'utils/classnames'
import { useLocale, useTranslations } from 'next-intl'
import * as React from 'react'
import { ButtonIcon } from 'components/studio-button'

import { SearchBanner } from '../search-banner'
import { MenuIcon } from '@ask-cf/assets/icons'
import { StyledLink } from '../styled-link'
import { UserHeaderSection } from './components/user-header-section'
import { Links } from './components/navigation-links'
import { UserMenu } from './components/user-menu'
import { MobileSideMenu } from './components/mobile-side-menu'
import { ROUTES } from '@ask-cf/constants/app-routes'
import { useIsMainPage } from '@ask-cf/hooks/use-is-main-page'
import { DigestSignupBanner } from '../digest-signup-banner'
import { ASK_CF_FF_DIGEST_SIGNUP_BANNER } from '@ask-cf/config'

export function Navbar() {
  const [showMenu, setShowMenu] = React.useState(false)
  const [showUserProfileMenu, setShowUserProfileMenu] = React.useState(false)
  const locale = useLocale()
  const pathname = usePathname()
  const isMainPage = useIsMainPage()

  React.useEffect(() => {
    setShowMenu(false)
  }, [pathname])

  const t = useTranslations('ask_cf.components.navbar')

  return (
    <>
      <div
        className={tw(
          'bg-background-primary fixed top-0 z-20 w-full xl:pb-2',
          isMainPage ? 'pb-[60px]' : 'pb-2',
        )}
      >
        <nav
          className={tw(
            'flex h-[56px] w-full flex-row items-center bg-white px-4 xl:h-[84px] xl:px-16 xl:py-5',
          )}
        >
          <div
            className={tw(
              'xl:mx-auto xl:max-w-[1311px]',
              'flex w-full flex-row items-center gap-2 xl:gap-8',
              'pr-[--removed-body-scroll-bar-size]',
            )}
          >
            <ButtonIcon
              className="block xl:hidden"
              onClick={() => setShowMenu(current => !current)}
              aria-label={t('toggle_menu_aria_label')}
            >
              <MenuIcon className="text-text-tertiary" />
            </ButtonIcon>
            <StyledLink href={ROUTES.HOME(locale)} aria-label={t('home_link_aria_label')}>
              <CFAskLogo className="h-8 xl:h-11" />
            </StyledLink>
            <ul id="nav-links" className="hidden gap-6 xl:flex">
              <Links />
            </ul>
            <div className="ml-auto">
              <UserHeaderSection
                onUserMenuOpen={() => setShowUserProfileMenu(current => !current)}
              />
            </div>
          </div>
        </nav>
        {ASK_CF_FF_DIGEST_SIGNUP_BANNER ? <DigestSignupBanner /> : null}
        <SearchBanner />
      </div>
      <UserMenu isOpen={showUserProfileMenu} onClose={() => setShowUserProfileMenu(false)} />
      <MobileSideMenu isOpen={showMenu} onClose={() => setShowMenu(false)} />
    </>
  )
}
