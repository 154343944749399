import * as React from 'react'
import { useLocale, useTranslations } from 'next-intl'
import * as DropdownMenu from 'primitives/dropdown'
import { IconButton } from 'primitives/buttons/icon-button'
import { Tag } from 'primitives/tag'
import { StudioButton } from 'components/studio-button'
import { tw } from 'utils/classnames'
import { ChevronRightIcon } from 'primitives/icons'

import { Link } from '@ask-cf/components/link'
import { NotificationsIcon } from '@ask-cf/assets/icons'
import { NotificationModel, testNotifications } from '@ask-cf/routes/notifications/models/test-data'
import { useFormatTimeSpan } from '@ask-cf/hooks/use-format-time-span'
import { notificationTypes } from '@ask-cf/utils/notifications'
import { ROUTES } from '@ask-cf/constants/app-routes'
import { UserAvatar } from '@ask-cf/components/avatar'
import { StyledLink } from '@ask-cf/components/styled-link'
import { UserNameLabel } from '@ask-cf/components/user-name-label'

export function NotificationsMenu() {
  const [items, setItems] = React.useState<NotificationModel[]>(testNotifications)
  const [isOpened, setIsOpened] = React.useState(false)
  const displayedItems = items.slice(0, 6)
  const unreadCount = items.filter(item => !item.isRead).length
  const hasUnreadNotifications = unreadCount > 0
  const locale = useLocale()
  const t = useTranslations('ask_cf.pages.notifications')
  React.useEffect(() => {
    const closeWindow = () => setIsOpened(false)
    if (isOpened) {
      setTimeout(() => window.addEventListener('click', closeWindow), 10)
      return () => window.removeEventListener('click', closeWindow)
    }
  }, [isOpened])

  return (
    <DropdownMenu.Root open={isOpened}>
      <DropdownMenu.Trigger aria-label="notifications" onClick={() => setIsOpened(x => !x)}>
        <IconButton
          className="relative bg-transparent p-0 hover:bg-transparent active:bg-transparent"
          asChild
        >
          <div>
            <NotificationsIcon
              className="text-text-tertiary hover:text-text-hover"
              aria-label="notifications"
            />
            {hasUnreadNotifications ? (
              <div className="absolute bottom-[5px] right-0.5 h-2 w-2 rounded-full bg-pink-500"></div>
            ) : null}
          </div>
        </IconButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className="rounded-2 tablet:left-auto tablet:w-[392px] font-poppins shadow-dropdown z-50 mx-4 mt-[21px] flex flex-col overflow-hidden bg-white !p-0 xl:right-[calc(calc(calc(100dvw-1311px)/2)+53px)]">
          <DropdownMenu.Item className="flex flex-col">
            <NotificationsHeader
              unreadCount={unreadCount}
              setAllRead={() => setItems(prev => prev.map(x => ({ ...x, isRead: true })))}
            />
            {displayedItems.map((item, index) => (
              <NotificationItem key={index} notification={item} />
            ))}
            <div className="rounded-b-2 flex justify-center bg-white pb-[18.5px] pt-[19.5px]">
              <StyledLink
                href={ROUTES.NOTIFICATIONS(locale)}
                className="text-content-text-link text-sm font-medium leading-[21px]"
                aria-label={t('view_all')}
              >
                {t('view_all')}
                <ChevronRightIcon className="ml-0.5" width={24} height={24} />
              </StyledLink>
            </div>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

function NotificationsHeader({
  unreadCount,
  setAllRead,
}: {
  unreadCount: number
  setAllRead: () => void
}) {
  const t = useTranslations('ask_cf.pages.notifications')
  return (
    <header className="flex w-full items-center gap-2 border-b border-b-gray-400 px-4 py-5">
      <span className="text-text-context font-medium">{t('title')}</span>
      {unreadCount > 0 ? (
        <Tag className="flex h-[23px] items-center rounded-[24px] border-0 bg-pink-500 p-1.5 text-sm font-semibold leading-[21px] text-white">
          {unreadCount}
        </Tag>
      ) : null}

      <StudioButton
        variant="invisible"
        onClick={setAllRead}
        className="text-content-text-link hover:text-text-hover ml-auto h-auto p-0 text-sm font-medium leading-5"
        aria-label={t('mark_all_as_read')}
      >
        {t('mark_all_as_read')}
      </StudioButton>
    </header>
  )
}

function NotificationItem({ notification }: { notification: NotificationModel }) {
  const t = useTranslations('ask_cf.components.notifications')
  const displayedUsersCount = 4
  const notificationTypeModel = notificationTypes[notification.type]
  const NotificationIcon = notificationTypeModel.notificationIcon
  const displayedUsers = notification.users.slice(0, displayedUsersCount)
  const notDisplayedUsersCount = notification.users.length - displayedUsersCount
  const formatTimeSpan = useFormatTimeSpan()
  const locale = useLocale()
  const timeElapsed = React.useMemo(
    () =>
      notification.createdAt &&
      formatTimeSpan(new Date().getTime() - notification.createdAt.getTime()),
    [notification.createdAt, formatTimeSpan],
  )
  return (
    <Link
      className="relative cursor-pointer border-b border-b-gray-400 bg-white py-3 pl-4 pr-[55px]"
      href={ROUTES.QUESTION_DETAILS(notification.topicSlug, notification.questionSlug, locale)}
    >
      <div className="flex items-center gap-2">
        <div className="flex">
          <div
            className="flex h-6 w-6 items-center justify-center rounded-full"
            style={{ backgroundColor: notificationTypeModel.color }}
          >
            <NotificationIcon
              style={{ color: notificationTypeModel.fontColor }}
              width={12}
              height={12}
            />
          </div>
          {displayedUsers.map(user => (
            <div className="-ml-1.5 rounded-full border border-white" key={user.id}>
              <UserAvatar avatarUrl={user.avatarUrl} size="small" />
            </div>
          ))}
          {notDisplayedUsersCount > 0 ? (
            <div
              className={tw(
                '-ml-1.5 flex h-[26px] w-[26px] border-white bg-blue-400 text-white',
                'items-center justify-center rounded-full border text-[10.5px] font-semibold',
              )}
            >
              +{notDisplayedUsersCount}
            </div>
          ) : null}
        </div>
        <div className="text-text-primary text-xs font-medium leading-4">
          {displayedUsers.length === 1 ? (
            <UserNameLabel className="font-semibold">{displayedUsers[0].name}</UserNameLabel>
          ) : null}{' '}
          {t(`notification_type_messages.${notificationTypeModel.translationKey}`)}
        </div>
      </div>
      <div className="text-text-tertiary mt-2.5 text-xs leading-4" suppressHydrationWarning>
        {timeElapsed}
      </div>
      {!notification.isRead ? (
        <div className="absolute right-[18px] top-[14px] h-2 w-2 rounded-full bg-pink-500" />
      ) : null}
    </Link>
  )
}
