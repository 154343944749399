'use client'

import * as React from 'react'
import { motion } from 'framer-motion'
import { tw } from 'utils/classnames'
import { useLocale, useTranslations } from 'next-intl'
import { useIsLoggedInQuery } from 'auth/hooks'
import { usePathname } from 'next/navigation'
import { useUserAuthModal } from 'components/user-auth-dialog/user-auth-dialog-context'

import { Link } from '@ask-cf/components/link'
import { AlgoliaProvider } from '@ask-cf/algolia'
import { NewReleasesIcon, SearchIcon } from '@ask-cf/assets/icons'
import { AlgoliaContentHit, useAutocomplete } from '@ask-cf/algolia/algolia.hooks'
import { TOPICS_LIST, Topic } from '@ask-cf/constants/topics'
import { formatNumber } from '@ask-cf/utils/math'
import { ROUTES } from '@ask-cf/constants/app-routes'
import { useQuestionDialog } from '@ask-cf/contexts/question-dialog-context'
import { StyledLink } from '@ask-cf/components/styled-link'
import { useNotifyLoginDialog } from '@ask-cf/contexts/notify-login-dialog-context'
import { ASK_CF_FF_BEST_ANSWERS } from '@ask-cf/config'

export function QuestionInputWrapper({ scrollActive }: { scrollActive: boolean }) {
  return (
    <AlgoliaProvider>
      <QuestionInput scrollActive={scrollActive} />
    </AlgoliaProvider>
  )
}

function QuestionInput({ scrollActive }: { scrollActive: boolean }) {
  const locale = useLocale()
  const t = useTranslations('ask_cf.components.search_banner')
  const inputRef = React.useRef<HTMLInputElement>(null)
  const autocomplete = useAutocomplete()
  const { openDialog } = useQuestionDialog()
  const [searchString, setSearchString] = React.useState('')
  const [showHits, setShowHits] = React.useState(false)
  const [caretUpdateNeeded, setCaretUpdateNeeded] = React.useState(false)
  const isLoggedInQuery = useIsLoggedInQuery()
  const { setMessage } = useNotifyLoginDialog()
  const { openModal } = useUserAuthModal()
  const pathname = usePathname()

  React.useEffect(() => {
    const dropdownCloseTimeoutMs = 10

    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setShowHits(false)
      }
    }

    if (showHits) {
      setTimeout(() => {
        window.addEventListener('click', handleClickOutside)
      }, dropdownCloseTimeoutMs * 10)

      return () => {
        window.removeEventListener('click', handleClickOutside)
      }
    }
  }, [showHits])

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
    setSearchString('')
  }, [pathname])

  React.useEffect(() => {
    const handleScroll = () => setShowHits(false)

    if (showHits) {
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [showHits])

  function handleSearch(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if (!isLoggedInQuery.isLoading && !isLoggedInQuery.data) {
      setMessage(t('log_in_dialog_title'))
      openModal('login', {
        modalOpenChangeCallback: () => (open: boolean) => {
          if (!open) {
            setMessage('')
          }
        },
      })
      return
    } else {
      openDialog(searchString)
    }
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    autocomplete.refine(e.target.value)
    setSearchString(e.target.value)
  }

  const hits = React.useMemo(
    () =>
      autocomplete.indices[0].hits
        .filter(hit => hit.question && hit.question.trim().length > 0)
        .slice(0, 3)
        .map(hit => hit as unknown as AlgoliaContentHit),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputRef.current?.value],
  )

  React.useEffect(() => {
    setShowHits(
      (hits.length > 0 && inputRef.current?.value.length && inputRef.current?.value.length > 1) ||
        false,
    )
  }, [hits])

  const topTopics = React.useMemo(() => {
    // Create an object to store the count of each topic
    const topicCounts: { [topic: string]: number } = {}

    // Iterate over the array of objects and count the occurrences of each topic
    autocomplete.indices[0].hits.forEach(obj => {
      const { topic_slug } = obj
      if (!topic_slug || typeof topic_slug === 'undefined') {
        return
      }
      topicCounts[topic_slug] = (topicCounts[topic_slug] || 0) + 1
    })

    // Convert the topic counts object into an array of objects with topic and count properties
    const topicsArray = Object.entries(topicCounts).map(([topic_slug, count]) => ({
      topic_slug,
      count,
    }))

    // Sort the topics array based on the count in descending order
    topicsArray.sort((a, b) => b.count - a.count)

    const topTwoTopics = topicsArray.slice(0, 2).map(({ topic_slug, count }) => {
      const algoliaTopic = autocomplete.indices[0].hits.find(hit => hit.topic_slug === topic_slug)
      const { icon, bgColor, iconColor } =
        TOPICS_LIST.find(topic => topic.slug === topic_slug) || {}
      return {
        title: algoliaTopic?.topic,
        icon,
        bgColor,
        iconColor,
        questionCount: count,
        slug: topic_slug,
      }
    })

    return topTwoTopics
  }, [autocomplete.indices])

  React.useEffect(() => {
    if (caretUpdateNeeded && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.setSelectionRange(0, 0)
        setCaretUpdateNeeded(false)
      }, 0)
    }
  }, [caretUpdateNeeded, searchString])

  const handleInputFocus = () => {
    setShowHits(
      (hits.length > 0 && inputRef.current?.value.length && inputRef.current?.value.length > 1) ||
        false,
    )
    if (searchString?.length === 0 && inputRef.current) {
      setCaretUpdateNeeded(true)
    }
  }

  return (
    <form
      className={tw(
        'relative w-full xl:w-auto xl:max-w-[100%] xl:self-start',
        'xl:mb-[22px] xl:mt-5',
        scrollActive && 'm-0 h-12 xl:m-0',
      )}
      onSubmit={handleSearch}
    >
      <div
        className={tw(
          'rounded-4 relative flex h-10 w-full flex-row self-start py-2 pl-4 xl:w-[624px] xl:max-w-[100%] xl:rounded-[30px]',
          'bg-white pr-2 xl:h-[72px] xl:py-4 xl:pl-8 xl:pr-6',
          scrollActive && 'h-10 xl:h-[56px] xl:py-2 xl:pl-6',
          showHits && 'rounded-b-none xl:rounded-b-none',
        )}
      >
        <input
          type="text"
          autoComplete="off"
          ref={inputRef}
          id="search-banner-input"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          className={tw(
            'placeholder:text-text-quinary w-full pr-2 placeholder:text-sm placeholder:opacity-30 xl:placeholder:text-lg',
            'min-[375px]:text-md py-0.5 text-sm xl:py-2',
            'flex-grow bg-transparent',
            showHits || searchString ? 'pr-24 xl:pr-32' : 'pr-7',
            scrollActive && 'pr-24 xl:pr-28',
          )}
          placeholder={t('search_bar_placeholder')}
        />
        <motion.button
          whileHover={{ scale: 1.03 }}
          type="submit"
          className={tw(
            'absolute right-2 top-2',
            'rounded-full bg-pink-500 text-white',
            'duration-400 transition-all',
            'p-1',
            'xl:right-6 xl:top-4 xl:p-2',
            scrollActive && 'xl:right-2 xl:top-2',
            showHits || searchString ? 'p-0 px-2 xl:p-2 xl:px-4 ' : '',
          )}
          aria-label={t('ask_new_question_button')}
        >
          {showHits || searchString ? (
            <>
              <span className="inline text-xs min-[375px]:inline xl:text-sm">
                {t('ask_new_question_button_short')}
              </span>
            </>
          ) : (
            <SearchIcon className={tw('h-4 w-4 xl:h-6 xl:w-6')} />
          )}
        </motion.button>
      </div>
      {showHits ? (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          id="autocomplete-suggestions"
          className={tw(
            'z-20 max-w-full bg-white',
            'rounded-b-4 w-full self-start overflow-hidden shadow-[0_10px_15px_-4px_rgba(0,0,0,0.3)] xl:w-[624px] xl:max-w-[100%] xl:rounded-b-[30px]',
            'border-t-[1px] border-t-[#DFDFDF] border-opacity-60',
          )}
        >
          {hits.map(hit => (
            <AutocompleteResult key={hit.question} hit={hit} />
          ))}
          <div className="mb-2 px-4 py-[2px] xl:px-8">
            <StyledLink
              href={ROUTES.SEARCH_RESULTS(searchString, '1', locale)}
              onClick={() => setShowHits(false)}
              className="text-xxs text-content-text-link flex flex-row items-center font-medium min-[375px]:text-xs xl:text-sm"
              aria-label={t('view_related_questions_link')}
            >
              {t('view_related_questions_link')}
            </StyledLink>
          </div>
          {topTopics?.length ? (
            <div className="h-[72px] border-t-[1px] border-t-[#DFDFDF] border-opacity-60">
              <TopTopics topTopics={topTopics} />
            </div>
          ) : null}
        </motion.div>
      ) : null}
    </form>
  )
}

function TopTopics({ topTopics }: { topTopics: Partial<Topic>[] }) {
  return (
    <div className="flex w-full flex-row px-4 py-4 xl:px-8">
      {topTopics.map(topic => (
        <TopicCard key={topic.title} {...topic} />
      ))}
    </div>
  )
}

function TopicCard(props: Partial<Topic>) {
  const t = useTranslations('ask_cf.components.search_banner')
  const locale = useLocale()
  const { id, title, questionCount, icon, bgColor, iconColor, slug } = props
  return (
    <motion.div
      whileHover={{ scale: 1.03 }}
      id={`topic-card-${id}`}
      className={tw('group w-[50%] cursor-pointer')}
    >
      <Link
        className="flex h-10 w-full flex-row gap-2"
        href={ROUTES.TOPIC_DETAILS(slug || '', locale)}
      >
        <div
          id={`topic-card-${id}-icon`}
          className={tw('rounded-2 aspect-[1] p-2', bgColor, iconColor)}
        >
          {icon}
        </div>
        <div id={`topic-card-${id}-details`} className="flex h-auto flex-col justify-center gap-1">
          <p
            id={`topic-card-${id}-title`}
            className={tw(
              'text-text-secondary font-medium leading-5 min-[375px]:text-sm',
              'text-xxs',
              'group-hover:text-text-hover',
            )}
          >
            {title}
          </p>
          <p
            id={`topic-card-${id}-questionCount`}
            className="text-text-quinary hidden text-xs font-medium leading-4 min-[375px]:block"
          >
            {formatNumber(questionCount || 0)} {t('questions')}
          </p>
        </div>
      </Link>
    </motion.div>
  )
}

// TODO: this needs to be updated to use the actual Algolia data type once that is known
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function AutocompleteResult({ hit }: { hit: AlgoliaContentHit }) {
  const locale = useLocale()
  const t = useTranslations('ask_cf.components.search_banner')
  const hasAnswers = hit.numberofanswers > 0
  const hasTopic = hit.topic && hit.topic.trim().length > 0
  const href = `${ROUTES.QUESTION_DETAILS(hit.topic_slug, hit.question_slug, locale)}`
  return (
    <motion.div
      whileHover={{ scale: 1.03 }}
      key={hit.question}
      className="group flex max-w-full flex-row flex-wrap items-center px-4 py-2 hover:cursor-pointer xl:px-8"
    >
      <Link href={href} target="_blank" className="group-hover:text-text-hover inline-block">
        <p className="min-[375px]:text-md line-clamp-1 text-ellipsis text-sm">{hit.question}</p>{' '}
        {ASK_CF_FF_BEST_ANSWERS ? (
          <NewReleasesIcon className="mr-2 inline min-h-[14px] min-w-[14px]" />
        ) : null}
        {hasAnswers ? (
          <span className="text-xxs text-text-tertiary mr-1 inline whitespace-nowrap font-normal min-[375px]:text-xs">
            {hit.numberofanswers} {t('answers')}
          </span>
        ) : null}
        {hasAnswers && hasTopic ? (
          <span className="text-xxs text-text-tertiary inline font-normal min-[375px]:text-xs">
            •
          </span>
        ) : null}
        {hasTopic ? (
          <span className="text-xxs text-text-tertiary ml-1 font-normal min-[375px]:text-xs">
            {hit.topic}
          </span>
        ) : null}
      </Link>
    </motion.div>
  )
}
