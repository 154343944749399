'use client'
import { UserAuthDialog } from 'components/user-auth-dialog'

import { useNotifyLoginDialog } from '@ask-cf/contexts/notify-login-dialog-context'

export function LoginDialog() {
  const { message } = useNotifyLoginDialog()
  return message ? (
    <UserAuthDialog>
      <LoginBanner message={message} />
    </UserAuthDialog>
  ) : (
    <UserAuthDialog />
  )
}

export function LoginBanner({ message }: { message: string }) {
  return (
    <div className="rounded-tl-2 rounded-tr-2 -mb-12 w-full bg-pink-500 py-3 text-center text-white">
      <p>{message}</p>
    </div>
  )
}
