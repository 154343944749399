export function resolveGraphQLUrl() {
  return (
    process.env.GRAPHQL_URL_SELF_HOSTED ||
    process.env.NEXT_PUBLIC_GRAPHQL_URL ||
    process.env.VITE_GRAPHQL_URL
  )
}

export function getCookie(cname: string) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }

  return ''
}

export const TOKEN_LOCAL_STORAGE_KEY = 'cfToken'
