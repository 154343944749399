'use client'
import { useLocale, useTranslations } from 'next-intl'
import { tw } from 'utils/classnames'
import { CFAskLogo } from 'primitives/icons'
import { useIsLoggedInQuery } from 'auth/hooks'
import { useUserAuthModal } from 'components/user-auth-dialog/user-auth-dialog-context'

import {
  InstagramIcon,
  FacebookIcon,
  PinterestIcon,
  YoutubeIcon,
  TikTokIcon,
} from '@ask-cf/assets/icons'
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  PINTEREST_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
  TIKTOK_URL,
  YOUTUBE_URL,
} from '@ask-cf/constants/links'
import { ROUTES } from '@ask-cf/constants/app-routes'
import { StyledLink } from '../styled-link'
import { useQuestionDialog } from '@ask-cf/contexts/question-dialog-context'
import { ASK_CF_FF_BADGES } from '@ask-cf/config'
import { useNotifyLoginDialog } from '@ask-cf/contexts/notify-login-dialog-context'

export function Footer() {
  return (
    <footer className="text-text-secondary z-10">
      <div
        id="footer-content"
        className={tw('bg-service-white w-full font-[300]', 'px-4', 'sm:px-[80px]')}
      >
        <div className="mx-auto max-w-[1439px]">
          <div
            id="footer-social"
            className={tw(
              'flex flex-col flex-wrap justify-between gap-12 pb-[63px] pt-[72px]',
              'sm:flex-row sm:gap-12',
            )}
          >
            <SocialLinks />
            <Links />
            <Contact />
          </div>
          <Legal />
        </div>
      </div>
      <div id="footer-bottom-line" className="h-[11px] w-full bg-pink-500"></div>
    </footer>
  )
}

function SocialLinks() {
  const t = useTranslations('ask_cf.components.footer.social')
  const locale = useLocale()
  return (
    <div id="footer-social-links" className="w-full xl:w-auto">
      <StyledLink href={ROUTES.HOME(locale)} aria-label={t('home_link_aria_label')}>
        <CFAskLogo />
      </StyledLink>
      <p className="mb-8 mt-4 max-w-[480px] text-lg font-medium">
        {t.rich('description', { br: () => <br /> })}
      </p>
      <div className="flex flex-row gap-5">
        <StyledLink
          target="_blank"
          rel="noopener noreferrer"
          href={INSTAGRAM_URL}
          aria-label={t('instagram_link_aria_label')}
        >
          <InstagramIcon />
        </StyledLink>
        <StyledLink
          target="_blank"
          rel="noopener noreferrer"
          href={FACEBOOK_URL}
          aria-label={t('facebook_link_aria_label')}
        >
          <FacebookIcon />
        </StyledLink>
        <StyledLink
          target="_blank"
          rel="noopener noreferrer"
          href={YOUTUBE_URL}
          aria-label={t('youtube_link_aria_label')}
        >
          <YoutubeIcon />
        </StyledLink>
        <StyledLink
          target="_blank"
          rel="noopener noreferrer"
          href={TIKTOK_URL}
          aria-label={t('tiktok_link_aria_label')}
        >
          <TikTokIcon />
        </StyledLink>
        <StyledLink
          target="_blank"
          rel="noopener noreferrer"
          href={PINTEREST_URL}
          aria-label={t('pinterest_link_aria_label')}
        >
          <PinterestIcon />
        </StyledLink>
      </div>
    </div>
  )
}

function Legal() {
  const t = useTranslations('ask_cf.components.footer.legal')
  return (
    <div
      id="footer-legal"
      className={tw(
        'flex flex-col justify-between gap-4 border-t-[1px] border-opacity-35 py-4',
        'sm:flex-row sm:py-10',
      )}
    >
      <p className="text-text-secondary text-sm">
        {t.rich('rights_reserved', {
          year: () => {
            const now = new Date()
            return now.getFullYear()
          },
        })}
      </p>
      <div id="links" className={tw('flex flex-col gap-4 text-sm', 'sm:flex-row sm:gap-10')}>
        <StyledLink target="_blank" rel="noopener noreferrer" href={TERMS_OF_SERVICE_URL}>
          {t('terms_of_service')}
        </StyledLink>
        <StyledLink target="_blank" rel="noopener noreferrer" href={PRIVACY_POLICY_URL}>
          {t('privacy_policy')}
        </StyledLink>
      </div>
    </div>
  )
}

function Contact() {
  const t = useTranslations('ask_cf.components.footer.contact')

  return (
    <div id="footer-contact" className={tw('font-16 flex flex-col gap-4', '')}>
      <div>
        <p className="font-semibold">{t('contact_us')}</p>
        <p>{t('contact_email')}</p>
      </div>
      <div>
        <p className="font-semibold">{t('find_us')}</p>
        <p>{t.rich('cf_address', { br: () => <br /> })}</p>
      </div>
      <div>
        <p>{t('chamber_of_commerce')}</p>
        <p>{t('vat_number')}</p>
      </div>
    </div>
  )
}

function Links() {
  const { openDialog } = useQuestionDialog()
  const isLoggedInQuery = useIsLoggedInQuery()
  const t = useTranslations('ask_cf.components.footer.links')
  const locale = useLocale()
  const { setMessage } = useNotifyLoginDialog()
  const { openModal } = useUserAuthModal()

  const openAskQuestionDialog = () => {
    if (!isLoggedInQuery.isLoading && !isLoggedInQuery.data) {
      setMessage(t('log_in_dialog_title'))
      openModal('login', {
        modalOpenChangeCallback: () => (open: boolean) => {
          if (!open) {
            setMessage('')
          }
        },
      })
    } else {
      openDialog()
    }
  }

  const handleBadgeLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!isLoggedInQuery) {
      e.preventDefault()
      setMessage(t('log_in_dialog_badge_title'))
      openModal('login', {
        modalOpenChangeCallback: () => (open: boolean) => {
          if (!open) {
            setMessage('')
          }
        },
      })
    }
  }

  return (
    <nav id="footer-links">
      <ol className="flex min-w-[150px] flex-col gap-[18px]">
        <li>
          <StyledLink href={ROUTES.ABOUT(locale)}>{t('about')}</StyledLink>
        </li>
        <li>
          <StyledLink href={ROUTES.ALL_TOPICS('', locale)}>{t('all_topics')}</StyledLink>
        </li>
        {ASK_CF_FF_BADGES ? (
          <li>
            <StyledLink href={ROUTES.BADGES(locale)} onClick={handleBadgeLinkClick}>
              {t('badges')}
            </StyledLink>
          </li>
        ) : null}
        <li>
          <StyledLink
            href={''}
            onClick={openAskQuestionDialog}
            aria-disabled={isLoggedInQuery.isLoading}
          >
            {t('ask_a_question')}
          </StyledLink>
        </li>
      </ol>
    </nav>
  )
}
