'use client'
import { LazyMotion, domAnimation, AnimatePresence, m } from 'framer-motion'
import * as Dialog from 'primitives/dialog'
import { IconButton } from 'primitives/buttons/icon-button'
import { XCloseIcon } from 'primitives/icons'
import { mergeFormData } from 'utils/dom'
import { UserAuthResponse } from 'utils/auth'
import { tw } from 'utils/classnames'
import {
  trackLoginModalOpen,
  trackRegistrationModalOpen,
  AuthModalLocations,
} from 'analytics/amplitude/utils'
import { useUsersUpdateFlagsMutation } from 'codegen/generated/user'
import { useRefetchAuth } from 'auth/use-refetch-auth'
import { getUserIdSelector } from 'auth/selectors'
import { useSetUserQuery } from 'auth/hooks'

import { useUserAuthModal } from './user-auth-dialog-context'
import { LoginForm } from './login-form'
import { LoginOtp } from './login-otp'
import { RegisterForm } from './register-form'
import { RegisterOtp } from './register-otp'
import { LostPassword } from './lost-password'
import { trackRegistration } from './utils/track-registration'

export type Step = 'login' | 'login-otp' | 'register' | 'register-otp' | 'forget-password'

export type UserAuthDialogProps = {
  bodyClassName?: string
  children?: React.ReactNode
}

export function UserAuthDialog({ bodyClassName = '', children }: UserAuthDialogProps) {
  const {
    isOpen,
    setIsOpen,
    step,
    setStep,
    formState,
    setFormState,
    errorMessage,
    successMessage,
    setErrorMessage,
    setSuccessMessage,
    successCallback,
    modalOpenChangeCallback,
  } = useUserAuthModal()
  const { refetchAuth } = useRefetchAuth()
  const { setUserQuery } = useSetUserQuery()
// eslint-disable-next-line local/codegen-mutation-patterns
  const updateUserFlags = useUsersUpdateFlagsMutation()

  async function onSuccess(me: UserAuthResponse) {
    setUserQuery({ me })
    await refetchAuth()
    setIsOpen(false)
    if (successCallback) {
      successCallback()
    }

    if (me.user?.flags?.identifyUser) {
      await updateUserFlags.mutateAsync({
        input: {
          identifyUser: false,
        },
      })
    }
  }

  async function onRegisterSuccess(me: UserAuthResponse) {
    onSuccess(me)
    const userFlags = me.user?.flags

    const userId = getUserIdSelector({ me }) || ''
    trackRegistration({
      isGAdsSignup: userFlags?.isGAdsSignup || true,
      isNew: userFlags?.isNew || false,
      userId,
    })

    if (userFlags?.isGAdsSignup || userFlags?.isNew) {
      await updateUserFlags.mutateAsync({
        input: {
          isGAdsSignup: false,
          isNew: false,
        },
      })
    }
  }

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open)
    setErrorMessage(null)
    setSuccessMessage(null)
    if (modalOpenChangeCallback) {
      modalOpenChangeCallback(open)
    }
  }

  return (
    <div className="relative z-[1300]">
      <LazyMotion features={domAnimation}>
        <Dialog.Root defaultOpen={false} open={isOpen} onOpenChange={handleOpenChange}>
          <Dialog.Body dismissable={false}>
            <div
              className={tw(
                'relative flex h-fit max-h-[100vh] w-full flex-col items-center overflow-auto p-5',
                bodyClassName,
              )}
            >
              <div className="bg-service-white rounded-2 relative w-full max-w-[400px] grow ">
                {children}
                <div
                  className={tw(
                    'p-5 pb-10',
                    children ? 'pt-16 md:pt-14 lg:pt-14 xl:pt-14' : 'pt-5',
                  )}
                >
                  <AnimatePresence initial={false}>
                    {errorMessage !== null || successMessage !== null ? (
                      <m.div
                        exit={{
                          height: 0,
                          opacity: 0,
                        }}
                        initial={{
                          height: 0,
                          opacity: 0,
                        }}
                        animate={{
                          height: 'auto',
                          opacity: 1,
                        }}
                        className="rounded-t-2 relative left-[-20px] top-[-20px] w-[calc(100%+40px)] overflow-hidden"
                      >
                        <p
                          className={tw(
                            errorMessage ? 'bg-[#ff4554]' : 'bg-[#3ab561]',
                            ' text-14 text-service-white p-2 text-center',
                          )}
                        >
                          {errorMessage ? errorMessage : successMessage}
                        </p>
                      </m.div>
                    ) : null}
                  </AnimatePresence>

                  {step === 'login' ? (
                    <div className="flex h-fit w-full flex-col items-center justify-center pt-2">
                      <LoginForm
                        onRegister={nextFormState => {
                          setFormState(mergeFormData(formState, nextFormState))
                          setStep('register')
                          trackRegistrationModalOpen(AuthModalLocations.LOGIN_MODAL)
                        }}
                        onOtp={nextFormState => {
                          setFormState(mergeFormData(formState, nextFormState))
                          setStep('login-otp')
                        }}
                        onLostPassword={nextFormState => {
                          setFormState(mergeFormData(formState, nextFormState))
                          setStep('forget-password')
                        }}
                        onSuccess={onSuccess}
                      />
                    </div>
                  ) : null}
                  {step === 'login-otp' ? (
                    <div className="flex h-fit w-full flex-col items-center justify-center pt-2">
                      <LoginOtp
                        onRegister={nextFormState => {
                          setFormState(mergeFormData(formState, nextFormState))
                          setStep('register')
                          trackRegistrationModalOpen(AuthModalLocations.LOGIN_MODAL)
                        }}
                        onLostPassword={nextFormState => {
                          setFormState(mergeFormData(formState, nextFormState))
                          setStep('forget-password')
                        }}
                        onSuccess={onSuccess}
                      />
                    </div>
                  ) : null}
                  {step === 'register' ? (
                    <div className="flex h-fit w-full flex-col items-center justify-center pt-2">
                      <RegisterForm
                        onLogin={nextFormState => {
                          setFormState(mergeFormData(formState, nextFormState))
                          setStep('login')
                          trackLoginModalOpen(AuthModalLocations.REGISTRATION_MODAL)
                        }}
                        onLoginOtp={nextFormState => {
                          setFormState(mergeFormData(formState, nextFormState))
                          setStep('login-otp')
                        }}
                        onRegisterOtp={nextFormState => {
                          setFormState(mergeFormData(formState, nextFormState))
                          setStep('register-otp')
                        }}
                        onLostPassword={nextFormState => {
                          setFormState(mergeFormData(formState, nextFormState))
                          setStep('forget-password')
                        }}
                        onLoginSuccess={onSuccess}
                        onRegisterSuccess={onRegisterSuccess}
                      />
                    </div>
                  ) : null}
                  {step === 'register-otp' ? (
                    <div className="flex h-fit w-full flex-col items-center justify-center pt-2">
                      <RegisterOtp onSuccess={onRegisterSuccess} />
                    </div>
                  ) : null}
                  {step === 'forget-password' ? (
                    <div className="flex h-fit w-full flex-col items-center justify-center pt-2">
                      <LostPassword
                        onLogin={nextFormState => {
                          setFormState(mergeFormData(formState, nextFormState))
                          setStep('login')
                        }}
                      />
                    </div>
                  ) : null}

                  <div className="absolute right-[-20px] top-[-20px]">
                    <Dialog.Close asChild>
                      <IconButton
                        variant="violet"
                        className="hover:bg-aubergine-500 rounded-full bg-[#493675] p-[5px] transition-transform"
                      >
                        <XCloseIcon className="text-service-white h-[26px] w-[26px]" />
                      </IconButton>
                    </Dialog.Close>
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Body>
        </Dialog.Root>
      </LazyMotion>
    </div>
  )
}
