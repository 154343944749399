'use client'

import { tw } from 'utils/classnames'
import * as React from 'react'
import { useAskCfGetUserDigestStatusQuery } from 'codegen/generated/ask-cf'

import { BannerContext } from '@ask-cf/contexts/banner-context'
import { ASK_CF_FF_DIGEST_SIGNUP_BANNER } from '@ask-cf/config'

export function MainContent({
  children,
  breadcrumbs,
}: {
  children: React.ReactNode
  breadcrumbs?: React.ReactNode
}) {
  const { bannerActive } = React.useContext(BannerContext)
  const { data: isSubscribed } = useAskCfGetUserDigestStatusQuery(undefined, {
    select: data => data?.askCFGetUserDigestStatus?.subscribed,
  })

  let paddingClass = ''
  if (isSubscribed || !ASK_CF_FF_DIGEST_SIGNUP_BANNER) {
    paddingClass = bannerActive ? 'pt-[146px] xl:pt-[204px]' : 'pt-[208px] xl:pt-[391px]'
  } else {
    paddingClass = bannerActive ? 'pt-[193px] xl:pt-[251px]' : 'pt-[255px] xl:pt-[438px]'
  }
  return (
    <div
      id="main-content"
      className={tw(
        'w-full px-4 pb-[52px] xl:px-[64px] xl:pb-[48px] 2xl:mx-auto 2xl:max-w-[1311px] 2xl:px-0',
        paddingClass,
      )}
    >
      {breadcrumbs}
      <div className="flex flex-grow items-center">
        {/* Fix for context not updating the component here, if anyone has a better idea please let me know */}
        {bannerActive ? ' ' : null}
        {children}
      </div>
    </div>
  )
}
