'use client'

import { AppleWhiteIcon, FacebookWhiteIcon, GoogleFasIcon } from 'primitives/icons/social'
import { useTranslations } from 'next-intl'
import invariant from 'tiny-invariant'

const CF_URL = process.env.NEXT_PUBLIC_CF_URL || process.env.VITE_CF_URL

invariant(CF_URL, 'NEXT_PUBLIC_CF_URL inside SocialButtons is required')

export function SocialButtons() {
  const t = useTranslations('social')
  const href = typeof window !== undefined ? window.location.href : ''

  const returnUrl = encodeURIComponent(href)

  return (
    <div className="flex w-full flex-col gap-[10px]">
      <a
        rel="noreferrer"
        href={`${CF_URL}/?wc-api=auth&start=facebook&return=${returnUrl}`}
        className="text-14 text-service-white flex h-[45px] w-full items-center justify-center gap-[10px] rounded bg-[#3b5898] px-5 uppercase hover:opacity-80"
      >
        <FacebookWhiteIcon className="h-[20px] w-[20px]" />
        {t('facebook')}
      </a>
      <a
        rel="noreferrer"
        href={`${CF_URL}/?wc-api=auth&start=google&return=${returnUrl}`}
        className="text-14 text-service-white flex h-[45px] w-full items-center justify-center gap-[10px] rounded bg-[#ff4554] px-5 uppercase hover:opacity-80"
      >
        <GoogleFasIcon className="h-[20px] w-[20px]" />
        {t('google')}
      </a>
      <a
        rel="noreferrer"
        href={`${CF_URL}/?wc-api=auth&start=apple&return=${returnUrl}`}
        className="text-14 text-service-white flex h-[45px] w-full items-center justify-center gap-[10px] rounded bg-[#000000] px-5 uppercase hover:opacity-80"
      >
        <AppleWhiteIcon className="h-[20px] w-[20px]" />
        {t('apple')}
      </a>
    </div>
  )
}
