'use client'
import * as React from 'react'
import { useLostPasswordMutation } from 'codegen/generated/user'
import { MailFasIcon } from 'primitives/icons/communication'
import { CheckIcon, SpinnerIcon } from 'primitives/icons'
import * as Form from 'primitives/form'
import { Divider } from 'components/divider'
import { isError } from 'utils/js'
import { useTranslations } from 'next-intl'

import { useUserAuthModal } from './user-auth-dialog-context'

export function LostPassword({ onLogin }: { onLogin(formState: FormData): void }) {
  const t = useTranslations('lost_password')
  const { formState, setErrorMessage, setSuccessMessage } = useUserAuthModal()
  const [success, setSuccess] = React.useState(false)

// eslint-disable-next-line local/codegen-mutation-patterns
  const lostPassword = useLostPasswordMutation({
    onSuccess: async function onSuccessCallback(data) {
      if (data.lostPassword?.success) {
        setSuccess(true)
        setSuccessMessage(t('success'))

        return
      }

      if (
        data.lostPassword?.errors?.find(err => err.message.includes('google_email_already_exists'))
      ) {
        throw new Error('CF_GOOGLE_EMAIL_ALREADY_EXISTS')
      }

      if (
        data.lostPassword?.errors?.find(err =>
          err.message.includes('facebook_email_already_exists'),
        )
      ) {
        throw new Error('CF_FACEBOOK_EMAIL_ALREADY_EXISTS')
      }

      if (
        data.lostPassword?.errors?.find(err => err.message.includes('apple_email_already_exists'))
      ) {
        throw new Error('CF_APPLE_EMAIL_ALREADY_EXISTS')
      }

      throw new Error('CF_INVALID_EMAIL')
    },
  })

  const ref = React.useRef<HTMLFormElement>(null)

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const formData = new FormData(ref.current!)

    const email = formData.get('email')?.toString() ?? ''

    setErrorMessage(null)

    lostPassword.mutate(
      {
        input: {
          email,
        },
      },
      {
        onSuccess: async function onSuccessCallback() {
          setSuccess(true)
          setSuccessMessage(t('success'))
        },
      },
    )
  }

  function onLoginClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    const form = new FormData(ref.current!)
    onLogin(form)
  }

  React.useEffect(() => {
    function resolveErrorMessage() {
      if (lostPassword.isLoading || !lostPassword.isError) {
        return null
      }

      if (lostPassword.isPaused) {
        setErrorMessage(t('errors.offline'))
        return
      }

      if (isError(lostPassword.error) && lostPassword.error.message === 'CF_INVALID_EMAIL') {
        setErrorMessage(t('errors.invalid_details'))
        return
      }

      if (
        isError(lostPassword.error) &&
        lostPassword.error.message === 'CF_GOOGLE_EMAIL_ALREADY_EXISTS'
      ) {
        setErrorMessage(t('errors.google_email_existing'))
        return
      }

      if (
        isError(lostPassword.error) &&
        lostPassword.error.message === 'CF_FACEBOOK_EMAIL_ALREADY_EXISTS'
      ) {
        setErrorMessage(t('errors.facebook_email_existing'))
        return
      }

      if (
        isError(lostPassword.error) &&
        lostPassword.error.message === 'CF_APPLE_EMAIL_ALREADY_EXISTS'
      ) {
        setErrorMessage(t('errors.apple_email_existing'))
        return
      }

      setErrorMessage(t.rich('errors.general', { br: () => <br /> }))
    }

    resolveErrorMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lostPassword.isError, lostPassword.isLoading, lostPassword.isPaused, lostPassword.error])

  return (
    <div className="flex w-full flex-col">
      {success ? (
        <div className="flex gap-[10px]">
          <div className="bg-[#3ab561] mt-1 flex h-[20px] w-[20px] items-center justify-center rounded-full">
            <CheckIcon className="text-service-white h-[16px] w-[16px]" />
          </div>
          <p className="text-20 font-bold">{t('confirmation')}</p>
        </div>
      ) : (
        <>
          <h2 className="text-20 mb-4 text-center font-bold">{t('title')}</h2>
          <span className="text-14 mt-[5px] text-center">{t('copy')}</span>

          <Form.Root ref={ref} onSubmit={onSubmit} className="mt-[5px] flex w-full flex-col gap-4">
            <Form.Field name="email">
              <Form.Label className="text-12 align-text-top">{t('email')} *</Form.Label>
              <div className="relative flex w-full rounded border border-[#e0e2ea] pl-[10px]">
                <div className="pointer-events-none absolute inset-y-0 flex items-center">
                  <MailFasIcon className="h-[14px] w-[14px] text-[#e3e1e9]" />
                </div>
                <Form.Control asChild>
                  <input
                    required
                    defaultValue={formState?.get('email')?.toString()}
                    autoComplete="email"
                    type="email"
                    name="email"
                    id="email"
                    className="text-14 h-[38px] w-full p-3 pl-7 outline-none"
                    autoFocus
                    disabled={lostPassword.isLoading}
                  />
                </Form.Control>
              </div>
            </Form.Field>

            <Form.Submit asChild>
              <button
                disabled={lostPassword.isLoading}
                className="bg-[#3ab561] hover:bg-[#248142] text-service-white text-14 mt-2 flex h-[45px] w-full items-center justify-center rounded font-bold uppercase transition-[background-color]"
              >
                {lostPassword.isLoading ? (
                  <SpinnerIcon className="h-[24px] w-[24px] animate-spin" />
                ) : (
                  t('cta')
                )}
              </button>
            </Form.Submit>
          </Form.Root>
          <Divider className="mt-2 bg-[#DFDDE5]" />
          <div className="text-14 m-auto mt-4">
            {t.rich('login', {
              login: str => (
                <span
                  onClick={onLoginClick}
                  className="text-14 hover:text-[#3ab561] m-auto mt-4 cursor-pointer text-[#0a5ea8] underline"
                >
                  {str}
                </span>
              ),
            })}
          </div>
        </>
      )}
    </div>
  )
}
