import invariant from 'tiny-invariant'
import { SpanStatusCode, trace } from '@opentelemetry/api'

import type { FetchOptions } from './fetcher.types'
import { resolveGraphQLUrl, getCookie, TOKEN_LOCAL_STORAGE_KEY } from './fetcher.utils'

export const GRAPHQL_URL = resolveGraphQLUrl()
export const INTERNAL_GRAPHQL_URL = process.env.INTERNAL_GRAPHQL_URL || GRAPHQL_URL

const LOCAL_AUTH_TOKEN =
  process.env.NEXT_PUBLIC_LOCAL_AUTH_TOKEN || process.env.VITE_LOCAL_AUTH_TOKEN
const WP_COOKIE_NAME = process.env.NEXT_PUBLIC_WP_COOKIE_NAME || process.env.VITE_WP_COOKIE_NAME

invariant(GRAPHQL_URL, 'GRAPHQL_URL must be set inside packages/codegen/fetcher.ts')
invariant(WP_COOKIE_NAME, 'WP_COOKIE_NAME must be set inside packages/codegen/fetcher.ts')

const tracer = trace.getTracer('default')

export function resolveToken() {
  try {
    const tokenFromStorage = localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)

    if (tokenFromStorage) {
      return tokenFromStorage
    }
  } catch {}

  if (LOCAL_AUTH_TOKEN) {
    return LOCAL_AUTH_TOKEN
  }

  return null
}

export function resolveCookie() {
  return getCookie(WP_COOKIE_NAME!)
}

const limit = process.env.NEXT_PUBLIC_FETCHER_LIMIT
  ? parseInt(process.env.NEXT_PUBLIC_FETCHER_LIMIT)
  : 10_000

async function graphql<Data, Variables>(
  query: string,
  variables?: Variables,
  initialHeaders?: HeadersInit,
  fetchOptions?: RequestInit,
  gql: 'default' | 'internal' = 'default',
) {
  const cleanQuery = getCleanQuery(query)
  const now = Date.now()

  const headers = new Headers(initialHeaders)

  headers.append('accept', 'application/json, multipart/mixed')
  headers.append('content-type', 'application/json')

  const token = resolveToken()
  if (token) {
    headers.append('Authorization', `Bearer ${token}`)
  }

  const res = await fetch(gql === 'default' ? GRAPHQL_URL! : INTERNAL_GRAPHQL_URL!, {
    ...fetchOptions,
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({ query, variables }),
  })

  const duration = Date.now() - now
  if (duration > limit) {
    // eslint-disable-next-line no-console
    console.warn(
      `GraphQL fetcher: ${duration}ms`,
      gql === 'default' ? GRAPHQL_URL! : INTERNAL_GRAPHQL_URL!,
      cleanQuery,
    )
  }
  const json = await res.json()

  handleErrors(json.errors)

  return json.data as Data
}

function handleErrors(errors: unknown) {
  if (!Array.isArray(errors)) {
    return
  }

  // in dev, log the errors to the console
  const captured = new Set<string>()

  for (const error of errors) {
    if (captured.has(error.message)) {
      continue
    }
    captured.add(error.message)

    // eslint-disable-next-line no-console
    console.error(error.message)
  }

  const { message } = errors[0] || {}

  if (message) {
    throw new Error(message)
  }
}

export function fetcher<Data, Variables>(
  query: string,
  variables?: Variables,
  headers?: HeadersInit,
  { sensitive = true, gql = 'default', ...fetchOptions }: FetchOptions = {},
) {
  return async function fetcherInner() {
    return tracer.startActiveSpan('graphql', async span => {
      span.setAttribute('sensitive', String(sensitive))
      span.setAttribute('graphql.url', GRAPHQL_URL || 'undefined')

      if (!sensitive) {
        span.setAttribute('query', getCleanQuery(query))
        span.setAttribute('variables', stringify(variables))
        span.setAttribute('fetchOptions', stringify(fetchOptions))
      }

      return graphql<Data, Variables>(query, variables, headers, fetchOptions, gql)
        .then(res => {
          span.setStatus({ code: SpanStatusCode.OK })
          return res
        })
        .catch(err => {
          span.setStatus({
            code: SpanStatusCode.ERROR,
            message: err instanceof Error ? err.message : String(err),
          })
          throw err
        })
        .finally(() => {
          span.end()
        })
    })
  }
}

function stringify(options: unknown) {
  try {
    return JSON.stringify(options)
  } catch {
    return 'Failed to parse'
  }
}

function getCleanQuery(query: string) {
  // remove any \n or remove multiple white spaces
  return query.replace(/\n/g, '').replace(/\s+/g, ' ')
}
