import * as React from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Type = React.ComponentType<any>

export function lazyComponent<T extends Type>(component: () => Promise<T>) {
  const loader = () => component().then(t => ({ default: t }))
  const LazyComponent = React.lazy(loader)

  return {
    component: LazyComponent,
    preload: loader,
  }
}

export type LazyComponent = ReturnType<typeof lazyComponent>
