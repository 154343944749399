'use client'

import * as React from 'react'

import { useIsMainPage } from '@ask-cf/hooks/use-is-main-page'

export interface BannerContextModel {
  bannerActive: boolean
}

export const BannerContext = React.createContext<BannerContextModel>({
  bannerActive: false,
})

export const BannerProvider = ({ children }: React.PropsWithChildren) => {
  const isMainPage = useIsMainPage()

  // Scroll is minimized everywhere except the main page
  const value = !isMainPage

  return <BannerContext.Provider value={{ bannerActive: value }}>{children}</BannerContext.Provider>
}
