import(/* webpackMode: "eager", webpackExports: ["MainContent"] */ "/vercel/path0/apps/ask-cf/app/[locale]/main-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","src"] */ "/vercel/path0/apps/ask-cf/assets/images/meta_image.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["default","src"] */ "/vercel/path0/apps/ask-cf/assets/images/twitter_meta_image.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/ask-cf/components/answer-dialog/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/ask-cf/components/footer/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/ask-cf/components/google-one-tap/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/ask-cf/components/login-dialog/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/vercel/path0/apps/ask-cf/components/navbar/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/ask-cf/components/not-enough-coins-dialog/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/ask-cf/components/notification-settings-dialog/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/apps/ask-cf/components/providers/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/ask-cf/components/question-dialog/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/ask-cf/components/report-question-dialog/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollResetOnReload"] */ "/vercel/path0/apps/ask-cf/components/scroll-reset-on-reload/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/ask-cf/components/social-share-dialog/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BannerProvider"] */ "/vercel/path0/apps/ask-cf/contexts/banner-context.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/ask-cf/styles/main.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["Amplitude"] */ "/vercel/path0/packages/analytics/amplitude/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalSiteTag"] */ "/vercel/path0/packages/analytics/next-specific/global-site-tag.tsx");
