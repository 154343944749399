import * as Dialog from 'primitives/dialog'
import * as React from 'react'
import Image from 'next/image'
import { useTranslations } from 'next-intl'
import { useAskCfConfirmQuestionMutation } from 'codegen/generated/ask-cf'
import { tw } from 'utils/classnames'

import { useQuestionDialog } from '@ask-cf/contexts/question-dialog-context'
import { ConfirmationBannerMobile } from './confirmation-banner-mobile'
import SupportTopicConfirmationImage from '../assets/support-topic-confirmation.webp'
import { StyledButton } from '@ask-cf/components/styled-button'
import { useCreateZendeskTicketMutation } from '@ask-cf/hooks/use-create-zendesk-ticket'
import { useUser } from '@ask-cf/hooks/use-user-data'
import { WarningIcon } from '@ask-cf/assets/icons'

export function QuestionDialogSupportTopicConfirmation({
  questionId,
  onSupportSubmitNext,
  onConfirmPostNext,
}: {
  questionId: string
  onSupportSubmitNext: () => void
  onConfirmPostNext: () => void
}) {
  const { closeDialog, question, questionContext, images } = useQuestionDialog()
  const t = useTranslations('ask_cf.components.question_dialog.confirmations.support_topic')
  const createZendeskTicketMutation = useCreateZendeskTicketMutation()
  const askCfConfirmQuestionMutation = useAskCfConfirmQuestionMutation()
  const [hasSubmitError, setHasSubmitError] = React.useState(false)
  const user = useUser()

  async function handleSubmitQuestion() {
    try {
      await createZendeskTicketMutation.mutateAsync({
        params: {
          subject: `Ask CF - ${question}`,
          comment: {
            body: questionContext || question,
          },
          requester: {
            name: user.data?.firstName || 'Crafter',
            email: user.data?.email || '',
          },
          fields: {},
          priority: 'low',
          tags: ['ask-cf'],
        },
        images: images,
      })

      onSupportSubmitNext()
    } catch (error) {
      setHasSubmitError(true)
      new Error('Failed to create ticket:', error as Error)
    }
  }

  function handleConfirmQuestion() {
    askCfConfirmQuestionMutation.mutate(
      { input: { questionId: questionId } },
      { onSuccess: () => onConfirmPostNext() },
    )
  }

  return (
    <div
      className={tw(
        'xl:rounded-2 relative flex h-full w-full flex-col bg-blue-400 pt-4 xl:max-w-[430px] xl:flex-row xl:pt-0',
        hasSubmitError ? 'xl:h-[622px]' : 'xl:h-[570px]',
      )}
    >
      <div className={'shrink-0 xl:hidden'}>
        <ConfirmationBannerMobile onClose={closeDialog} title={t('title')} />
      </div>
      <div className="rounded-t-4 xl:rounded-2 z-10 flex grow flex-col items-center overflow-y-auto bg-white px-6 py-5">
        <div className="text-blue-1000 mb-1 hidden text-center text-[28px] font-medium leading-[39.2px] xl:block xl:max-w-[380px]">
          {t('title')}
        </div>
        <Image
          width={219}
          height={213}
          src={SupportTopicConfirmationImage}
          alt="Ask support"
          className="mt-4"
        />
        <div className="mt-3 text-center text-sm leading-5 text-gray-600 xl:max-w-[330px]">
          {t('content')}
        </div>
        <div className="mt-auto flex flex-col items-center">
          <StyledButton
            variant="gray"
            className="py-2 text-sm font-semibold leading-[24px] text-blue-700 xl:h-[56px] xl:w-[328px]"
            onClick={handleConfirmQuestion}
          >
            {t('post_anyway_button_label')}
          </StyledButton>
          <StyledButton
            variant="blue"
            className="mt-4 py-2 text-sm font-semibold leading-[24px] xl:h-[56px] xl:w-[328px]"
            onClick={handleSubmitQuestion}
          >
            {t('next_button_label')}
          </StyledButton>
          {hasSubmitError ? (
            <div className="mt-3 flex flex-row items-center gap-2 rounded bg-red-100 p-2 text-sm font-medium text-red-800">
              <WarningIcon />
              <span>{t('submit_error')}</span>
            </div>
          ) : null}
        </div>
        <Dialog.CloseX className="hover:bg-text-hover right-0 top-0 z-50 hidden h-7 w-7 -translate-y-1/2 translate-x-1/2 items-center justify-center bg-blue-700 text-white xl:flex" />
      </div>
    </div>
  )
}
