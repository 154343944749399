import { tw } from 'utils/classnames'

export function Divider({
  className,
  orientation = 'horizontal',
}: {
  className?: string
  orientation?: 'horizontal' | 'vertical'
}) {
  if (orientation === 'horizontal') {
    return <div className={tw('mx-auto h-[1px] w-full bg-gray-100', className)} />
  }

  return <div className={tw('mx-auto w-[1px] bg-gray-100', className)} />
}
