import Image from 'next/image'
import * as Dialog from 'primitives/dialog'
import * as Accordion from 'primitives/accordion'
import * as React from 'react'
import { useTranslations } from 'next-intl'
import { tw } from 'utils/classnames'
import { IconButton } from 'primitives/buttons/icon-button'
import { RichTextEditor } from 'primitives/rich-text-editor'
import { getHtmlCharacterCount } from 'utils/dom'
import TextareaAutosize from 'react-textarea-autosize'
import { useAskCfPostQuestionMutation } from 'codegen/generated/ask-cf'
import { AskCfAssetTypeInput } from 'codegen/types'
import { SpinnerIcon } from 'primitives/icons'

import {
  AddImageAlternateIcon,
  AddNotesIcon,
  ArrowLeftIcon,
  ChatInfoIcon,
} from '@ask-cf/assets/icons'
import { StyledButton } from '@ask-cf/components/styled-button'
import AskCfTipsAvatar from '../assets/ask-cf-question-tips.webp'
import { BubblePointer } from './bubble-pointer'
import { Question } from './question-dialog-body'
import { ImageUpload } from '@ask-cf/components/image-upload'
import { useQuestionDialog } from '@ask-cf/contexts/question-dialog-context'
import { UploadedFile, useUploadsImage } from '@ask-cf/hooks/use-image-upload'

const MAX_QUESTION_LENGTH = 200
const MAX_CONTEXT_LENGTH = 200
const MIN_QUESTION_LENGTH = 10
const MAX_QUESTION_CHARACTER_LENGTH = 2000

export function QuestionDialogFormView({
  onNext,
  questionId,
}: {
  onNext: (question: Question) => void
  questionId: string
}) {
  const {
    closeClearModalData,
    question,
    setQuestion,
    questionContext,
    setQuestionContext,
    images,
    setImages,
  } = useQuestionDialog()
  const t = useTranslations('ask_cf.components.question_dialog')
  const [showImageUpload, setShowImageUpload] = React.useState(images.length > 0)
  const [showContextState, setShowContext] = React.useState(false)
  const [questionMarkAdded, setQuestionMarkAdded] = React.useState(false)
  const [caretUpdateNeeded, setCaretUpdateNeeded] = React.useState(false)
  const questionInputRef = React.useRef<HTMLTextAreaElement>(null)
  const showQuestionLengthWarning = question?.length > MAX_QUESTION_LENGTH
  const [afterInitialFocus, setIsAfterInitialFocus] = React.useState(false)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const askCfPostQuestionMutation = useAskCfPostQuestionMutation()
  const { uploadImages, loading } = useUploadsImage()
  const [imagesLoading, setImagesLoading] = React.useState(false)

  const showContext = React.useMemo(() => {
    if (showQuestionLengthWarning && !showContextState) {
      return true
    }
    if (questionContext.length > 0 && !showContextState) {
      return true
    }
    return showContextState
  }, [showQuestionLengthWarning, showContextState, questionContext.length])

  const showLongContextLayout = getHtmlCharacterCount(questionContext) > MAX_CONTEXT_LENGTH
  const showMobileTips = !question
  const isMobile = window.innerWidth < 960

  React.useEffect(() => {
    if (caretUpdateNeeded && questionInputRef.current) {
      setTimeout(() => {
        questionInputRef.current?.setSelectionRange(0, 0)
        setCaretUpdateNeeded(false)
      }, 10)
    }
  }, [caretUpdateNeeded, question])

  const handleQuestionFocus = () => {
    const element = questionInputRef.current
    if (!element) {
      return
    }

    if (isMobile) {
      // Delay focus management to account for keyboard appearance on mobile
      setTimeout(() => {
        if (element) {
          const scrollY = element.getBoundingClientRect().top + window.scrollY - 250
          window.scrollTo({ top: scrollY, behavior: 'smooth' })
          if (question?.length === 0 && !questionMarkAdded) {
            setQuestion('?')
            setQuestionMarkAdded(true)
            setCaretUpdateNeeded(true)
            element.focus()
          }
        }
      }, 300)
    } else {
      if (question?.length === 0 && !questionMarkAdded && afterInitialFocus) {
        setQuestion('?')
        setQuestionMarkAdded(true)
        setCaretUpdateNeeded(true)
      }
      // This is necessary due to html <dialog> automatically focusing the input when dialog is opened
      if (!afterInitialFocus) {
        setIsAfterInitialFocus(true)
        questionInputRef.current?.blur()
      }

      setTimeout(() => {
        element.focus()

        if (
          questionMarkAdded &&
          question?.length === 0 &&
          !questionMarkAdded &&
          afterInitialFocus
        ) {
          element.setSelectionRange(0, 0)
        }
      }, 0)
    }
  }

  const handleFileSelect = (files: UploadedFile[]) => {
    setImages(prev => [...prev, ...files])
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault()
    const data = {
      question,
      context: questionContext,
      assets: images.map(image => ({
        assetType: AskCfAssetTypeInput.Image,
        assetUrl: image.uploadedUrl,
      })),
    }
    if (questionId) {
      const uploadUrls = await uploadImages(images) // if question resending is needed, we need to upload images first
      data.assets = uploadUrls.map(url => ({
        assetType: AskCfAssetTypeInput.Image,
        assetUrl: url.uploadedUrl,
      }))
    }

    askCfPostQuestionMutation.mutate(
      {
        input: data,
      },
      {
        onSuccess: data => {
          const apiResult = data.askCFPostQuestion.question
          if (apiResult) {
            const adaptedResult: Question = {
              question: apiResult.question,
              topic: apiResult.topic,
              uploadUrls:
                apiResult.assets?.map(
                  (asset: { url: string; optimizedUrl?: string | null }) =>
                    asset.optimizedUrl || asset.url,
                ) || [],
              id: apiResult.id,
              images: images,
              topicClassifierTimeout: apiResult.topicClassifierTimeout,
              slug: apiResult.questionSlug,
              topicSlug: apiResult.topicSlug,
            }
            onNext(adaptedResult)
          }
        },
      },
    )
  }

  React.useEffect(() => {
    if (question.length > MAX_QUESTION_CHARACTER_LENGTH) {
      setQuestion(question.slice(0, MAX_QUESTION_CHARACTER_LENGTH))
    }
  }, [question, setQuestion])

  return (
    <div
      className="xl:rounded-2 relative flex h-full w-full flex-col bg-blue-400 pt-4 xl:max-h-[814px] xl:min-h-[522px] xl:max-w-[1069px] xl:flex-row xl:pt-0"
      ref={containerRef}
    >
      <div className={'shrink-0 xl:w-[320px]'}>
        <BannerMobile onClose={closeClearModalData} showMobileTips={showMobileTips} />
        <BannerDesktop />
      </div>
      <form
        className="rounded-t-4 xl:rounded-r-2 z-20 flex grow flex-col overflow-y-auto bg-white px-6 py-4 xl:items-center xl:rounded-t-none"
        onSubmit={handleSubmit}
      >
        <div className={tw('h-[236px] w-full xl:pt-[7px]', showContext && 'shrink xl:h-fit')}>
          <TextareaAutosize
            className={tw(
              "placeholder:text-text-placeholder-darker min-h-full w-full bg-transparent p-0 text-lg leading-[36px] focus:outline-none focus:ring-0 focus:after:ml-0.5 focus:after:text-red-500 focus:after:content-['?'] xl:text-[24px] xl:leading-[43.2px]",
              showLongContextLayout && 'xl:text-lg xl:leading-[32.4px]',
            )}
            placeholder={t('type_your_question_placeholder')}
            name="prompt"
            id="prompt"
            value={question}
            onChange={e => setQuestion(e.target.value)}
            onFocus={handleQuestionFocus}
            ref={questionInputRef}
            maxRows={5}
            maxLength={MAX_QUESTION_CHARACTER_LENGTH}
          />
        </div>
        {showQuestionLengthWarning ? (
          <div className="mb-3 mt-4 flex gap-1">
            <ChatInfoIcon className="text-gray-500" />
            <span className="grow text-sm font-medium leading-5 text-gray-600">
              {t('question_length_warning')}
            </span>
          </div>
        ) : null}
        <Divider />
        {showContext ? (
          <StyledButton
            variant="gray"
            className="-mt-4 hidden w-full justify-start bg-transparent xl:flex"
            onClick={() => setShowContext(false)}
            preventDefault
          >
            <AddNotesIcon className="text-gray-500" />
            <span className="text-xs font-medium leading-4">
              {t('collapse_context_button_text')}
            </span>
          </StyledButton>
        ) : null}
        {!showContext && showImageUpload ? (
          <StyledButton
            variant="gray"
            className="-mt-4 hidden w-full justify-start xl:flex"
            onClick={() => setShowContext(true)}
            preventDefault
          >
            <AddNotesIcon className="text-gray-500" />
            <span className="text-xs font-medium leading-4">{t('add_context_button_text')}</span>
          </StyledButton>
        ) : null}
        <div className="flex flex-row gap-3 xl:hidden xl:w-full">
          <StyledButton
            variant="gray"
            className={tw(
              'grow xl:flex-col xl:py-8',
              showImageUpload && 'justify-start',
              showContext && 'bg-transparent',
            )}
            onClick={() => setShowContext(prev => !prev)}
            preventDefault
          >
            <AddNotesIcon className="text-gray-500" />
            <span className="text-xs font-medium leading-4">
              {t(showContext ? 'collapse_context_button_text' : 'add_context_button_text')}
            </span>
          </StyledButton>

          {!showImageUpload ? (
            <StyledButton
              variant="gray"
              className={tw('grow xl:flex-col xl:py-8', showContext && 'bg-transparent')}
              onClick={() => setShowImageUpload(true)}
              preventDefault
            >
              <AddImageAlternateIcon className="text-gray-500" />
              <span className="text-xs font-medium leading-4">{t('add_image_button_text')}</span>
            </StyledButton>
          ) : null}
        </div>
        <Accordion.Root
          type="single"
          collapsible
          className="w-full"
          value={showContext ? 'context' : undefined}
        >
          <Accordion.Item value={'context'}>
            <Accordion.Content className="-mx-2 px-2">
              <RichTextEditor
                className={tw('mt-2 h-[218px]')}
                onChange={setQuestionContext}
                initialValue={questionContext}
                placeholder={t('context_placeholder')}
                container={containerRef?.current}
              />
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
        <div className="hidden flex-row gap-3 xl:flex xl:w-full">
          {!showContext && !showImageUpload ? (
            <StyledButton
              variant="gray"
              className="grow xl:flex-col xl:py-8"
              onClick={() => setShowContext(true)}
              preventDefault
            >
              <AddNotesIcon className="text-gray-500" />
              <span className="text-xs font-medium leading-4">{t('add_context_button_text')}</span>
            </StyledButton>
          ) : null}
          {!showImageUpload && !showContext ? (
            <StyledButton
              variant="gray"
              className={tw('grow xl:flex-col xl:py-8', showContext && 'my-3')}
              onClick={() => setShowImageUpload(true)}
              preventDefault
            >
              <AddImageAlternateIcon className="text-gray-500" />
              <span className="text-xs font-medium leading-4">{t('add_image_button_text')}</span>
            </StyledButton>
          ) : null}
        </div>
        {showImageUpload || showContext ? (
          <ImageUpload
            images={images}
            onFileAdd={handleFileSelect}
            className="mb-4 mt-2 block w-full shrink-0"
            onFileDelete={index => setImages(prev => prev.filter((_, i) => i !== index))}
            maxImages={10}
            onFilesReordered={setImages}
            setLoading={setImagesLoading}
          />
        ) : null}
        <StyledButton
          variant="blue"
          className="mt-4 py-2 text-sm font-semibold leading-[24px] xl:mt-auto xl:w-[381px]"
          disabled={
            !question ||
            question.length < MIN_QUESTION_LENGTH ||
            askCfPostQuestionMutation.isLoading ||
            loading ||
            imagesLoading
          }
        >
          {askCfPostQuestionMutation.isLoading || loading ? (
            <SpinnerIcon className="mx-auto animate-spin" />
          ) : (
            t('ask_question_button_text')
          )}
        </StyledButton>
      </form>
      <Dialog.CloseX className="hover:bg-text-hover right-0 top-0 z-50 hidden h-7 w-7 -translate-y-1/2 translate-x-1/2 items-center justify-center bg-blue-700 text-white xl:flex" />
    </div>
  )
}

function BannerMobile({
  onClose,
  showMobileTips,
}: {
  onClose?: () => void
  showMobileTips: boolean
}) {
  const t = useTranslations('ask_cf.components.question_dialog')
  return (
    <>
      <div className={tw('relative overflow-hidden pl-5 xl:hidden', !showMobileTips && 'mb-4')}>
        <div className="flex items-center gap-2">
          <IconButton
            variant="transparent"
            onClick={onClose}
            aria-label={t('close_button_aria_label')}
            asChild
          >
            <ArrowLeftIcon className="text-white" />
          </IconButton>
          <span className="text-md font-medium leading-6 text-white">{t('banner_title')}</span>
        </div>
      </div>
      <div
        className={tw(
          'relative -mb-4 h-[170px] shrink min-[400px]:h-[140px] min-[520px]:h-[120px] min-[850px]:h-[100px] xl:hidden',
          !showMobileTips && '!h-4',
        )}
      >
        {showMobileTips ? (
          <>
            <Image
              width={90}
              height={145}
              className="absolute -right-5 top-0"
              src={AskCfTipsAvatar.src}
              alt={'Ask CF tips avatar'}
            />

            <div className="bg-transparent-100 relative -mt-3 mb-20 ml-7 h-[100px] w-[calc(100%-94px)] min-[400px]:mb-8 min-[520px]:mb-4 min-[850px]:mb-0">
              <AnimatedTip index={0} />
              <AnimatedTip index={1} />
              <AnimatedTip index={2} />
              <AnimatedTip index={3} />
            </div>
          </>
        ) : null}
      </div>
    </>
  )
}

function BannerDesktop() {
  const t = useTranslations('ask_cf.components.question_dialog')
  return (
    <div className="relative hidden h-full flex-col pl-6 pt-[26px] xl:flex">
      <div className="text-[28px] font-medium leading-[39.2px] text-white">{t('banner_title')}</div>
      <div className="relative grow">
        <div className="bg-transparent-100 absolute bottom-[220px] left-0 right-0 z-10 h-[200px]">
          <AnimatedTip index={0} />
          <AnimatedTip index={1} />
          <AnimatedTip index={2} />
          <AnimatedTip index={3} />
        </div>
        <Image
          width={200}
          height={273}
          className="absolute -right-[1px] bottom-[2px]"
          src={AskCfTipsAvatar.src}
          alt={'Ask CF tips avatar'}
        />
      </div>
    </div>
  )
}

function Divider() {
  return (
    <div className="mb-8 hidden w-full xl:block">
      <div className="h-[1px] w-full bg-gray-400 bg-opacity-50" />
    </div>
  )
}

function AnimatedTip({ index }: { index: number }) {
  const t = useTranslations('ask_cf.components.question_dialog')
  const animationDelay = `${index * 3}s`
  const animationStyle = {
    animationDelay: animationDelay,
  }
  return (
    <div
      className="absolute left-0 right-0 animate-[question-tip_12s_ease-in-out_infinite] opacity-0"
      style={animationStyle}
    >
      <div className="absolute left-0 right-5 top-0 rounded-[24px] bg-white pb-[10.42px] pl-[30px] pr-4 pt-[11.39px] text-sm font-medium leading-[20px] xl:bottom-[26.77px] xl:top-[initial]">
        {t(`banner_tip_${index}`)}
      </div>
      <BubblePointer className="absolute right-[8px] top-[10px] xl:bottom-0 xl:right-[33px] xl:top-[initial]" />
    </div>
  )
}
