'use client'
import * as React from 'react'
import * as Dialog from 'primitives/dialog'

import { SocialShareDialogBody } from './components/social-share-dialog-body'
import { useSocialShareDialog } from '@ask-cf/contexts/social-share-dialog-context'

export function SocialShareDialog() {
  const { isOpen, closeDialog, shareUrl } = useSocialShareDialog()

  return (
    <Dialog.Root open={isOpen} onOpenChange={open => !open && closeDialog()} defaultOpen={false}>
      <React.Suspense fallback={null}>
        <Dialog.Portal>
          <Dialog.Body
            className="font-poppins z-50 xl:overflow-y-auto xl:py-10"
            overlayZIndex={49}
            zIndex={49}
          >
            <SocialShareDialogBody shareUrl={shareUrl!} />
          </Dialog.Body>
        </Dialog.Portal>
      </React.Suspense>
    </Dialog.Root>
  )
}
