'use client'
import * as React from 'react'
import { useRegisterMutation } from 'codegen/generated/user'
import { ChallengeType } from 'codegen/types'
import { UserAuthResponse, getDeviceId, saveToken } from 'utils/auth'
import { LockFasIcon } from 'primitives/icons/security'
import { SpinnerIcon } from 'primitives/icons'
import * as Form from 'primitives/form'
import { Divider } from 'components/divider'
import { isError } from 'utils/js'
import { useTranslations } from 'next-intl'
import { isLoggedInSelector } from 'auth/selectors'

import { useUserAuthModal } from './user-auth-dialog-context'

export function RegisterOtp({ onSuccess }: { onSuccess(user: UserAuthResponse): void }) {
  const t = useTranslations('register_otp')
  const { formState, setStep, setErrorMessage } = useUserAuthModal()

  const registerMutation = useRegisterMutation({
    onSuccess: async function onSuccessCallback(data) {
      if (data.register?.token) {
        await saveToken(data.register.token, 'registration').catch(() => {
          throw new Error('CF_INVALID_TOKEN')
        })
        onSuccess(data.register)
        return
      }

      if (data.register?.challenge?.type === ChallengeType.ChallengeOtp) {
        return
      }

      if (data.register?.errors?.find(err => err.message === 'otp')) {
        throw new Error('CF_INVALID_OTP')
      }

      throw new Error('UNKNOWN_REGISTER_OTP_ERROR')
    },
  })

  const ref = React.useRef<HTMLFormElement>(null)

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const currentFormState = new FormData(e.currentTarget)

    const email = formState?.get('email')?.toString() ?? ''
    const password = formState?.get('password')?.toString() ?? ''
    const terms = formState?.get('terms')?.toString() === 'on'
    const deviceId = getDeviceId()
    const otp = currentFormState?.get('otp')?.toString() ?? ''

    registerMutation.mutate({
      input: {
        email,
        password,
        otp,
        deviceId,
        terms,
      },
    })
  }

  function onResendOTP(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()

    if (registerMutation.isLoading) {
      return null
    }

    const email = formState?.get('email')?.toString() ?? ''
    const password = formState?.get('password')?.toString() ?? ''
    const terms = formState?.get('terms')?.toString() === 'on'
    const deviceId = getDeviceId()

    registerMutation.mutate(
      {
        input: {
          email,
          password,
          deviceId,
          terms,
        },
      },
      {
        onSuccess(data) {
          if (data?.register?.challenge?.otp) {
            const element = ref.current?.querySelector('#otp')
            if (element instanceof HTMLInputElement) {
              element.value = data.register.challenge.otp.toString()
            }
          }
        },
      },
    )
  }

  const email = formState?.get('email')?.toString()

  React.useEffect(() => {
    function resolveErrorMessage() {
      if (registerMutation.isLoading || !registerMutation.isError) {
        return null
      }

      if (registerMutation.isPaused) {
        setErrorMessage(t('errors.offline'))
        return
      }

      if (isError(registerMutation.error) && registerMutation.error.message === 'CF_INVALID_OTP') {
        setErrorMessage(
          t.rich('errors.invalid_details', {
            resend: str => (
              <span
                onClick={onResendOTP}
                className="text-14 text-service-white hover:text-service-white cursor-pointer font-bold underline"
              >
                {str}
              </span>
            ),
          }),
        )
        return
      }

      setErrorMessage(t.rich('errors.general', { br: () => <br /> }))
    }

    resolveErrorMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    registerMutation.isError,
    registerMutation.isLoading,
    registerMutation.isPaused,
    registerMutation.error,
  ])

  const disabled = registerMutation.isLoading || isLoggedInSelector(registerMutation.data?.register)

  return (
    <div className="flex w-full flex-col">
      <h2 className="text-20 mb-4 text-center font-bold">{t('title')}</h2>
      <span className="text-14 mt-[5px] text-center">
        {t.rich('copy', {
          email: str => {
            if (email) {
              return <span className="font-bold">{email}</span>
            }

            return str
          },
        })}
      </span>
      <span
        onClick={() => {
          setStep('login')
        }}
        className="text-14 hover:text-[#3ab561] cursor-pointer text-center font-bold text-[#0a5ea8] underline"
      >
        {t('change_email')}
      </span>

      <Form.Root ref={ref} onSubmit={onSubmit} className="mt-[5px] flex w-full flex-col gap-4">
        <Form.Field name="otp">
          <Form.Label className="text-12 align-text-top">{t('otp')} *</Form.Label>
          <div className="relative flex w-full rounded border border-[#e0e2ea] pl-[10px]">
            <div className="pointer-events-none absolute inset-y-0 flex items-center">
              <LockFasIcon className="h-[14px] w-[14px] text-[#e3e1e9]" />
            </div>
            <Form.Control asChild>
              <input
                required
                defaultValue={formState?.get('otp')?.toString()}
                type="otp"
                name="otp"
                id="otp"
                className="text-14 h-[38px] w-full p-3 pl-7 outline-none"
                autoFocus
                disabled={disabled}
              />
            </Form.Control>
          </div>
        </Form.Field>

        <Form.Submit asChild>
          <button
            disabled={disabled}
            className="bg-[#3ab561] hover:bg-[#248142] text-service-white text-14 mt-2 flex h-[45px] w-full items-center justify-center rounded font-bold uppercase transition-[background-color]"
          >
            {disabled ? <SpinnerIcon className="h-[24px] w-[24px] animate-spin" /> : t('cta')}
          </button>
        </Form.Submit>
      </Form.Root>
      <Divider className="mt-2 bg-[#DFDDE5]" />
      <div className="text-14 m-auto mt-4">
        {t.rich('resend_otp', {
          resendOtp: str => (
            <span
              onClick={onResendOTP}
              className="text-14 hover:text-[#3ab561] cursor-pointer font-bold text-[#0a5ea8] underline"
            >
              {str}
            </span>
          ),
        })}
      </div>
    </div>
  )
}
