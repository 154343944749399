import { Slot } from 'primitives/slot'
import * as React from 'react'
import { tw } from 'utils/classnames'

interface ButtonProps {
  asChild?: boolean
  variant?: 'primary' | 'secondary'
}

function ButtonRoot(
  { asChild, variant = 'primary', ...props }: React.ComponentProps<'button'> & ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const className = tw(
    'rounded-2 text-md outline-none',
    props.disabled && 'cursor-not-allowed',
    variant === 'primary' &&
      'hover:bg-text-hover bg-blue-700 text-white active:bg-blue-700 active:text-white',
    variant === 'secondary' &&
      'bg-pink-500 text-white hover:bg-pink-700 active:bg-pink-500 active:text-white',
    'disabled:bg-gray-700 disabled:text-gray-400 disabled:hover:bg-gray-700 disabled:hover:text-gray-400',
    'flex h-[56px] items-center px-[16px]',
    props.className,
  )

  const gestures = {
    whileHover: {
      scale: 1.1,
    },
    whileTap: {
      scale: 1.05,
    },
  }

  const Comp = asChild ? Slot : 'button'

  return <Comp {...gestures} {...props} className={className} ref={ref} />
}

export function ButtonIcon(props: React.ComponentProps<'svg'>) {
  const Comp = Slot as unknown as 'svg'

  return <Comp {...props} className={tw('h-[24px] w-[24px]', props.className)} />
}

export const Button = Object.assign(React.forwardRef(ButtonRoot), {
  Icon: ButtonIcon,
})
