import * as React from 'react'
import { motion } from 'framer-motion'
import { tw } from 'utils/classnames'

export const SlideInMenu = ({
  children,
  isOpen,
  className,
  backdropClassName,
  position = 'left',
  onClose,
}: {
  children?: React.ReactNode
  isOpen: boolean
  className?: string
  backdropClassName?: string
  position?: 'left' | 'right'
  onClose: () => void
}) => {
  const menuVariants = {
    open: { x: 0 },
    closed: { x: position === 'left' ? '-100%' : '100%' },
  }

  return (
    <>
      <motion.div
        initial="closed"
        animate={isOpen ? 'open' : 'closed'}
        variants={menuVariants}
        transition={{ type: 'easeInOut', stiffness: 120 }}
        className={tw(
          'fixed bottom-0 top-0 z-20 w-[288px] overflow-hidden bg-white',
          position === 'left' ? 'left-0' : 'right-0',
          className,
        )}
      >
        {children}
      </motion.div>
      {isOpen ? (
        <div
          className={tw(
            'bg-background-backdrop fixed bottom-0 left-0 right-0 top-0 z-30 cursor-pointer',
            backdropClassName,
          )}
          onClick={onClose}
        />
      ) : null}
    </>
  )
}
