import { useTranslations } from 'next-intl'
import { useIsLoggedInQuery } from 'auth/hooks'
import { useUserAuthModal } from 'components/user-auth-dialog/user-auth-dialog-context'
import {
  useAskCfGetUserDigestStatusQuery,
  useAskCfUserDigestSubscribeMutation,
} from 'codegen/generated/ask-cf'
import { SpinnerIcon } from 'primitives/icons'

import { Button } from '../button'
import { useNotifyLoginDialog } from '@ask-cf/contexts/notify-login-dialog-context'
import { queryClient } from '@ask-cf/utils/query-client'

export function DigestSignupBanner() {
  const isLoggedInQuery = useIsLoggedInQuery()
  const userDigestStatusQuery = useAskCfGetUserDigestStatusQuery()
  const userDigestSubscribeMutation = useAskCfUserDigestSubscribeMutation()
  const { setMessage } = useNotifyLoginDialog()
  const { openModal } = useUserAuthModal()
  const t = useTranslations('ask_cf.components.digest_signup_banner')

  const handleButtonClick = () => {
    if (!isLoggedInQuery.isLoading && !isLoggedInQuery.data) {
      setMessage(t('log_in_dialog_title'))
      openModal('login', {
        modalOpenChangeCallback: () => (open: boolean) => {
          if (!open) {
            setMessage('')
          }
        },
      })
    } else {
      userDigestSubscribeMutation.mutateAsync(
        {},
        {
          onSuccess: () => {
            queryClient.refetchQueries(useAskCfGetUserDigestStatusQuery.getKey())
          },
        },
      )
    }
  }

  if (
    userDigestStatusQuery.isLoading ||
    userDigestStatusQuery.data?.askCFGetUserDigestStatus?.subscribed
  ) {
    return null
  }

  return (
    <div className="text-text-secondary mt-0.5 flex h-[45px] w-full flex-row items-center justify-between  bg-white px-5 xl:justify-center">
      <span className="mr-1 hidden text-sm font-light leading-[19.6px] xl:inline">
        {t('extended_label')}
      </span>
      <span className="mr-5 text-sm font-medium leading-[19.6px]">{t('cta_label')}</span>
      <Button
        onClick={handleButtonClick}
        variant="secondary"
        className="h-[30px] min-w-[81px] text-sm font-semibold"
        disabled={userDigestSubscribeMutation.isLoading}
      >
        {userDigestSubscribeMutation.isLoading ? (
          <SpinnerIcon className="mx-auto animate-spin" width={14} height={14} />
        ) : (
          t('button_label')
        )}
      </Button>
    </div>
  )
}
