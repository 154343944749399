import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister, $getNearestNodeOfType, $findMatchingParent } from '@lexical/utils'
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  $createParagraphNode,
  $isRootOrShadowRoot,
  $createNodeSelection,
  $setSelection,
  $isNodeSelection,
  TextNode,
  $getRoot,
  LexicalNode,
  ParagraphNode,
} from 'lexical'
import { $isHeadingNode } from '@lexical/rich-text'
import * as React from 'react'
import {
  INSERT_UNORDERED_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  insertList,
  $isListNode,
  ListNode,
  ListItemNode,
} from '@lexical/list'
import { TOGGLE_LINK_COMMAND, $isLinkNode } from '@lexical/link'
import { ListIcon } from 'primitives/icons'
import {
  BoldIcon,
  EmojiIcon,
  ItalicsIcon,
  LinkIcon,
  OrderedListIcon,
  UnderlineIcon,
} from 'primitives/icons/rich-text-editor'
import { $setBlocksType } from '@lexical/selection'
import { tw } from 'utils/classnames'
import debounce from 'lodash.debounce'

import { getSelectedNode } from '../utils/get-selected-node'
import { sanitizeUrl } from '../utils/url'
import { EmojiData, EmojiNode } from './emoji-plugin/emoji-node'
import { EmojiPicker } from './emoji-plugin/emoji-picker'

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  check: 'Check List',
  code: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
}

const LowPriority = 1

export function ToolbarPlugin({
  setIsLinkEditMode,
  container,
}: {
  setIsLinkEditMode: (value: boolean) => void
  container?: HTMLElement | null
}) {
  const [editor] = useLexicalComposerContext()
  const toolbarRef = React.useRef(null)
  const [isBold, setIsBold] = React.useState(false)
  const [isItalic, setIsItalic] = React.useState(false)
  const [isUnderline, setIsUnderline] = React.useState(false)
  const [isLink, setIsLink] = React.useState(false)
  const [blockType, setBlockType] = React.useState<keyof typeof blockTypeToBlockName>('paragraph')
  const [isEmojiMenuOpen, setIsEmojiMenuOpen] = React.useState(false)
  const [emojiMenuPosition, setEmojiMenuPosition] = React.useState({ x: 0, y: 0 })
  const emojiButtonRef = React.useRef<HTMLButtonElement>(null)

  const isEditable = editor.isEditable()

  const insertLink = React.useCallback(() => {
    if (!isLink) {
      setIsLinkEditMode(true)
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl(''))
    } else {
      setIsLinkEditMode(false)
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [editor, isLink, setIsLinkEditMode])

  function insertEmoji(emoji: EmojiData, event: MouseEvent): void {
    event.stopPropagation()
    const insertSafeForRoot = (node: LexicalNode, insertNode: LexicalNode) => {
      if (!node || node.getKey() === 'root') {
        // If no node is selected, we insert the emoji at the root level
        const root = $getRoot()
        const paragraph = $createParagraphNode()
        root.append(paragraph)
        paragraph.append(insertNode)
      } else if (node.__parent === 'root') {
        const paragraphNode = node as ParagraphNode
        paragraphNode.append(insertNode)
      } else if (node.__type === 'listitem') {
        const anyNode = node as ListItemNode
        anyNode.append(insertNode)
      } else {
        node.insertAfter(insertNode)
      }
    }

    editor.update(() => {
      const emojiNode = new EmojiNode(emoji)
      const selection = $getSelection()
      if (selection !== null) {
        if ($isRangeSelection(selection)) {
          const node = selection.getNodes()[0]
          insertSafeForRoot(node, emojiNode)
        } else if ($isNodeSelection(selection)) {
          const node = selection.getNodes()[0]
          if (node) {
            insertSafeForRoot(node, emojiNode)
          }
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const { anchor, focus } = selection as any
          if (anchor && focus) {
            insertSafeForRoot(anchor, emojiNode)
          }
        }
        const newSelection = $createNodeSelection()
        $setSelection(newSelection)
        newSelection.add(emojiNode.getKey())
      } else {
        const root = $getRoot()
        // If no selection is present, we insert the emoji at the end of the root
        if (root.getChildren().length === 0) {
          // Root is empty, create a new paragraph and append emojiNode
          const paragraph = $createParagraphNode()
          root.append(paragraph)
          paragraph.append(emojiNode)
        } else {
          // Root is not empty, append emojiNode to the last node
          const lastNode = root.getChildren().slice(-1)[0]
          insertSafeForRoot(lastNode, emojiNode)
        }
      }

      const zeroWidthSpaceCharCode = 8203
      const textNode = new TextNode(String.fromCharCode(zeroWidthSpaceCharCode))
      emojiNode.insertAfter(textNode)
      textNode.select()
      editor.focus()
    })
    setIsEmojiMenuOpen(false)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateEmojiMenuPosition = React.useCallback(
    debounce(() => {
      if (emojiButtonRef?.current) {
        const boundingRect = emojiButtonRef.current.getBoundingClientRect()
        setEmojiMenuPosition({
          x: boundingRect.left - 10,
          y: boundingRect.top,
        })
      }
    }, 50),
    [],
  )

  React.useEffect(() => {
    updateEmojiMenuPosition()
    window.addEventListener('resize', updateEmojiMenuPosition)
    return () => {
      window.removeEventListener('resize', updateEmojiMenuPosition)
    }
  }, [updateEmojiMenuPosition, emojiButtonRef])

  const updateToolbar = React.useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, e => {
              const parent = e.getParent()
              return parent !== null && $isRootOrShadowRoot(parent)
            })

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow()
      }
      // Update text format
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }
      if ($isListNode(element)) {
        const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode)
        const type = parentList ? parentList.getListType() : (node as ListNode).getListType()
        setBlockType(type)
      } else {
        const type = $isHeadingNode(element) ? element.getTag() : element.getType()
        if (type in blockTypeToBlockName) {
          setBlockType(type as keyof typeof blockTypeToBlockName)
        }
      }
    }
  }, [])

  const formatParagraph = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode())
      }
    })
  }

  const formatBulletList = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    } else {
      formatParagraph(e)
    }
  }

  const formatNumberedList = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
    } else {
      formatParagraph(e)
    }
  }

  const openEmojiMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setIsEmojiMenuOpen(prev => !prev)
  }

  React.useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar()
          return false
        },
        LowPriority,
      ),
      editor.registerCommand(
        INSERT_UNORDERED_LIST_COMMAND,
        () => {
          insertList(editor, 'bullet')
          return false
        },
        LowPriority,
      ),
    )
  }, [editor, updateToolbar])

  if (!isEditable) {
    return null
  }

  return (
    <div
      className="rounded-t-2 z-20 flex h-[56px] w-full items-center gap-5 overflow-x-auto bg-[#FAFBFC] px-4 sm:gap-[48px]"
      ref={toolbarRef}
      onClick={e => e.stopPropagation()}
    >
      <div className="flex gap-3 sm:gap-6">
        <button
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
          }}
          aria-label="Format Bold"
          className={tw('rounded', isBold && 'bg-[#E6E7E8]')}
        >
          <BoldIcon />
        </button>
        <button
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
          }}
          aria-label="Format Italics"
          className={tw('rounded', isItalic && 'bg-[#E6E7E8]')}
        >
          <ItalicsIcon />
        </button>
        <button
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
          }}
          aria-label="Format Underline"
          className={tw('rounded', isUnderline && 'bg-[#E6E7E8]')}
        >
          <UnderlineIcon />
        </button>
      </div>
      <div className="flex gap-3 sm:gap-6">
        <button onClick={formatBulletList} aria-label="Format Unordered List">
          <ListIcon />
        </button>
        <button onClick={formatNumberedList} aria-label="Format Ordered List">
          <OrderedListIcon />
        </button>
      </div>
      <div className="flex gap-3 sm:gap-6">
        <button onClick={insertLink} aria-label="Insert link" title="Insert link" type="button">
          <LinkIcon />
        </button>
      </div>
      <div className="flex gap-3 sm:gap-6">
        <button onClick={openEmojiMenu} aria-label="Add Emoji" ref={emojiButtonRef}>
          <EmojiIcon />
        </button>
        <EmojiPicker
          isOpen={isEmojiMenuOpen}
          x={emojiMenuPosition.x}
          y={emojiMenuPosition.y}
          insertEmoji={insertEmoji}
          onClose={() => setIsEmojiMenuOpen(false)}
          container={container}
        />
      </div>
    </div>
  )
}
