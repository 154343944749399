import Image from 'next/image'
import * as Dialog from 'primitives/dialog'
import * as React from 'react'
import { useLocale, useTranslations } from 'next-intl'
import { tw } from 'utils/classnames'
import { IconButton } from 'primitives/buttons/icon-button'
import { RichTextEditor } from 'primitives/rich-text-editor'
import { getHtmlCharacterCount } from 'utils/dom'
import { AskCfAssetTypeInput, AskCfPostAnswerInput } from 'codegen/types'
import { useAskCfPostAnswerMutation } from 'codegen/generated/ask-cf'
import { useRouter } from 'next/navigation'

import { AddImageAlternateIcon, ArrowLeftIcon } from '@ask-cf/assets/icons'
import { StyledButton } from '@ask-cf/components/styled-button'
import AskCfTipsAvatar from '../assets/ask-cf-question-tips.webp'
import { BubblePointer } from './bubble-pointer'
import { ImageUpload } from '@ask-cf/components/image-upload'
import { Post, ticketGenieUser } from '@ask-cf/components/feed/models'
import { UserAvatar } from '@ask-cf/components/avatar'
import { ASK_CF_FF_FOLLOW, ASK_GENIE_USER_ID } from '@ask-cf/config'
import { useFormatTimeSpan } from '@ask-cf/hooks/use-format-time-span'
import { UserNameLabel } from '@ask-cf/components/user-name-label'
import { UploadedFile } from '@ask-cf/hooks/use-image-upload'
import { ROUTES } from '@ask-cf/constants/app-routes'
import { useAnswerDialog } from '@ask-cf/contexts/answer-dialog-context'
import { FollowButton } from '@ask-cf/components/follow-button'

export function AnswerDialogBody({ post }: { post: Post }) {
  const { closeDialog } = useAnswerDialog()
  const t = useTranslations('ask_cf.components.answer_dialog')
  const [showImageUpload, setShowImageUpload] = React.useState(false)
  const [images, setImages] = React.useState<UploadedFile[]>([])
  const [imageLoading, setImageLoading] = React.useState(false)
  const [answer, setAnswer] = React.useState<string>('')
  const hasAnswer = !!getHtmlCharacterCount(answer)
  const formatTimeSpan = useFormatTimeSpan()
  const timeElapsed = React.useMemo(
    () => formatTimeSpan(new Date().getTime() - new Date(post.createTime).getTime()),
    [post.createTime, formatTimeSpan],
  )
  const containerRef = React.useRef<HTMLDivElement>(null)
  const askCfPostAnswerMutation = useAskCfPostAnswerMutation()
  const router = useRouter()
  const locale = useLocale()

  const handleFileSelect = (files: UploadedFile[]) => {
    setImages(prev => [...prev, ...files])
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault()
    const data: AskCfPostAnswerInput = {
      answer,
      questionId: post.id,
      assets: images.map(image => ({
        assetType: AskCfAssetTypeInput.Image,
        assetUrl: image.uploadedUrl,
      })),
    }

    askCfPostAnswerMutation.mutate(
      {
        input: data,
      },
      {
        onSuccess: data => {
          if (data.askCFPostAnswer.answer) {
            if (
              document.location.pathname !==
              ROUTES.QUESTION_DETAILS(post?.topicSlug || '', post?.questionSlug || '', locale)
            ) {
              router.push(
                ROUTES.QUESTION_DETAILS(post?.topicSlug || '', post?.questionSlug || '', locale),
              )
            } else {
              window.location.reload() //Next.js does not reload the page on route change if the route is the same
            }
            closeDialog()
          }
        },
      },
    )
  }

  return (
    <div
      className="xl:rounded-2 relative flex h-full w-full flex-col bg-blue-400 pt-4 xl:max-h-[814px] xl:min-h-[522px] xl:max-w-[1069px] xl:flex-row xl:pt-0"
      ref={containerRef}
    >
      <div className={'shrink-0 xl:w-[320px]'}>
        <BannerMobile onClose={closeDialog} showMobileTips={!hasAnswer} />
        <BannerDesktop post={post} />
      </div>
      <form
        className="rounded-t-4 xl:rounded-r-2 z-10 flex grow flex-col overflow-y-auto bg-white py-4 xl:items-center xl:rounded-t-none xl:px-6"
        onSubmit={handleSubmit}
      >
        <div className={tw('mb-4 w-full px-6 xl:mb-0 xl:pt-[13px]')}>
          <div className="flex items-center gap-2 xl:hidden">
            <UserAvatar avatarUrl={post.user.avatarUrl} size="mediumSecondary" />
            <div className="grow xl:grow-0">
              <div className="flex items-center">
                <UserNameLabel className="text-text-primary text-sm font-semibold leading-5">
                  {(post.user.firstName && post.user.lastName) || post.user.id === ASK_GENIE_USER_ID
                    ? `${post.user.firstName} ${post.user.lastName}`
                    : t('user_label')}
                </UserNameLabel>
                <span className="mx-0.5 text-xs font-medium leading-4 text-gray-600">&#x2022;</span>
                <span
                  className="text-xs font-medium leading-4 text-gray-600"
                  suppressHydrationWarning
                >
                  {timeElapsed}
                </span>
              </div>
              <div className="text-xs leading-4 text-gray-600">{post.user.subtitle}</div>
            </div>
            {ASK_CF_FF_FOLLOW && post.user.id !== ticketGenieUser.id ? (
              <FollowButton
                userId={post.user.id}
                following={post.user.following || false}
                hasSubtitle={!!post.user.subtitle}
              />
            ) : null}
          </div>
          <div
            className={tw(
              "placeholder:text-text-placeholder-darker mt-2 w-full bg-transparent p-0 text-sm leading-6 focus:outline-none focus:ring-0 focus:after:ml-0.5 focus:after:text-red-500 focus:after:content-['?'] xl:mt-0 xl:min-h-[128px] xl:text-lg xl:leading-[32.4px]",
            )}
          >
            {post.question}
          </div>
        </div>

        {!showImageUpload ? (
          <StyledButton
            variant="gray"
            className={tw('bg-transparent px-4 py-2 xl:hidden')}
            onClick={() => setShowImageUpload(true)}
          >
            <AddImageAlternateIcon className="text-gray-500" />
            <span className="text-xs font-medium leading-4">{t('add_image_button_text')}</span>
          </StyledButton>
        ) : null}
        <Divider />
        <RichTextEditor
          className={tw('mt-2 h-[218px]')}
          onChange={setAnswer}
          initialValue={answer}
          placeholder={t('answer_placeholder')}
          container={containerRef?.current}
        />
        <ImageUpload
          images={images}
          onFileAdd={handleFileSelect}
          className="mb-4 mt-2 hidden w-full shrink-0 flex-col xl:flex"
          onFileDelete={index => setImages(prev => prev.filter((_, i) => i !== index))}
          maxImages={10}
          onFilesReordered={setImages}
          setLoading={setImageLoading}
        />
        {showImageUpload ? (
          <ImageUpload
            images={images}
            onFileAdd={handleFileSelect}
            className="mx-6 mb-4 mt-2 flex w-full shrink-0 xl:hidden "
            onFileDelete={index => setImages(prev => prev.filter((_, i) => i !== index))}
            maxImages={10}
            onFilesReordered={setImages}
            setLoading={setImageLoading}
          />
        ) : null}
        <StyledButton
          variant="blue"
          className="mx-6 mt-4 py-2 text-sm font-semibold leading-[24px] xl:mx-0 xl:mt-auto xl:w-[381px]"
          disabled={imageLoading || !hasAnswer}
        >
          {t('add_answer_button_text')}
        </StyledButton>
      </form>
      <Dialog.CloseX className="hover:bg-text-hover right-0 top-0 z-50 hidden h-7 w-7 -translate-y-1/2 translate-x-1/2 items-center justify-center bg-blue-700 text-white xl:flex" />
    </div>
  )
}

function BannerMobile({
  onClose,
  showMobileTips,
}: {
  onClose?: () => void
  showMobileTips: boolean
}) {
  const t = useTranslations('ask_cf.components.answer_dialog')
  return (
    <>
      <div className={tw('relative overflow-hidden pl-5 xl:hidden', !showMobileTips && 'mb-4')}>
        <div className="flex items-center gap-2">
          <IconButton
            variant="transparent"
            onClick={onClose}
            aria-label={t('close_aria_label')}
            asChild
          >
            <ArrowLeftIcon className="text-white" />
          </IconButton>
          <span className="text-md font-medium leading-6 text-white">
            {t('banner_title_mobile')}
          </span>
        </div>
      </div>
      <div
        className={tw(
          'relative -mb-4 h-[170px] shrink transition-all duration-500 min-[400px]:h-[140px] min-[520px]:h-[120px] min-[850px]:h-[100px] xl:hidden',
          !showMobileTips && '!h-4',
        )}
      >
        {showMobileTips ? (
          <>
            <Image
              width={90}
              height={145}
              className="absolute -right-5 top-0"
              src={AskCfTipsAvatar.src}
              alt={'Ask CF tips avatar'}
            />

            <div className="bg-transparent-100 relative -mt-3 mb-20 ml-7 h-[100px] w-[calc(100%-94px)] min-[400px]:mb-8 min-[520px]:mb-4 min-[850px]:mb-0">
              <AnimatedTip index={0} />
              <AnimatedTip index={1} />
              <AnimatedTip index={2} />
              <AnimatedTip index={3} />
            </div>
          </>
        ) : null}
      </div>
    </>
  )
}

function BannerDesktop({ post }: { post: Post }) {
  const t = useTranslations('ask_cf.components.answer_dialog')
  return (
    <div className="relative hidden h-full flex-col pl-6 pt-[26px] xl:flex">
      <div className="text-[26px] font-medium leading-[36.4px] text-white">
        {t('banner_title_desktop')}
      </div>
      <div className="rounded-2 mr-6 mt-5 flex items-center gap-2 bg-blue-500 px-4 py-5">
        <UserAvatar
          avatarUrl={post.user.avatarUrl}
          size="mediumSecondary"
          className="border-2 border-white"
        />
        <div>
          <h3 className="flex items-center">
            <UserNameLabel className="text-sm font-semibold leading-5 text-white">
              {post.user.firstName} {post.user.lastName}
            </UserNameLabel>
          </h3>
          <div className="text-sm leading-5 text-white">{post.user.subtitle}</div>
        </div>
      </div>
      <div className="relative grow">
        <div className="bg-transparent-100 absolute bottom-[220px] left-0 right-0 z-10 h-[200px]">
          <AnimatedTip index={0} />
          <AnimatedTip index={1} />
          <AnimatedTip index={2} />
          <AnimatedTip index={3} />
        </div>
        <Image
          width={200}
          height={273}
          className="absolute -right-[1px] bottom-[2px]"
          src={AskCfTipsAvatar.src}
          alt={'Ask CF tips avatar'}
        />
      </div>
    </div>
  )
}

function Divider() {
  return (
    <div className="mb-8 hidden w-full xl:block">
      <div className="h-[1px] w-full bg-gray-400 bg-opacity-50" />
    </div>
  )
}

function AnimatedTip({ index }: { index: number }) {
  const t = useTranslations('ask_cf.components.answer_dialog')
  const animationDelay = `${index * 3}s`
  const animationStyle = {
    animationDelay: animationDelay,
  }
  return (
    <div
      className="absolute left-0 right-0 animate-[question-tip_12s_ease-in-out_infinite] opacity-0"
      style={animationStyle}
    >
      <div className="absolute left-0 right-5 top-0 rounded-[24px] bg-white pb-[10.42px] pl-[30px] pr-4 pt-[11.39px] text-sm font-medium leading-[20px] xl:bottom-[26.77px] xl:top-[initial]">
        {t(`banner_tip_${index}`)}
      </div>
      <BubblePointer className="absolute right-[8px] top-[10px] xl:bottom-0 xl:right-[33px] xl:top-[initial]" />
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// function FollowButton({ userId }: { userId: string }) {
//   const t = useTranslations('ask_cf.components.answer_dialog')
//   return (
//     <StyledButton
//       noAnimation
//       variant="transparent"
//       size="minimal"
//       className={tw('text-xs font-medium leading-4')}
//     >
//       {t('follow_button_text')}
//     </StyledButton>
//   )
// }
